import React, {FC, useEffect, useMemo} from 'react'
import { DongleListResponse } from '../../../../../../../../sdk/datagates/types/dongle/_crud/list'
import { CallsList } from './calls-list/CallsList'
import { CallsRedirectionList } from './calls-redirection-list/CallsRedirectionList'
import styles from './styles.module.scss'
import {ScreenDevices, useLessThanDeviceScreen} from "../../../../../../../../shared/lib/hooks/useLessThanDeviceScreen";

interface CallsListDesktopPageProps {
  devices: DongleListResponse['dongles']
  loading: boolean
}

export const CallsListPageContainer: FC<CallsListDesktopPageProps> = (props) => {
  const { devices, loading } = props;
  const isLaptopL = useLessThanDeviceScreen(ScreenDevices.LAPTOP_L)

  return (
    <div className={styles.Container}>
      <CallsList activeDevices={devices} activeDevicesLoading={loading} />
    </div>
  )
}
