
import React, {Dispatch, FC, SetStateAction, useMemo} from "react";
import {RowGroupType, Table, TableColType} from "../../../../../../../../shared/ui-kit-2/data-display/table/Table";
import {NoDevicesForCalls} from "../../../../../../../../layouts/status-layouts/no-devices-for-calls/NoDevicesForCalls";
import {
  CommonTableMessages
} from "../../../../../../../../config/intl/common-messages/common-table-messages/CommonTableMessages";
import {useIntl} from "react-intl";
import { ReactComponent as ArrowRightIcon } from '../../../../../../../../assets/icons/arrow-right.svg';
import { ReactComponent as TrashIcon } from '../../../../../../../../assets/icons/trash.svg';
import { ReactComponent as CaretRightIcon } from '../../../../../../../../assets/icons/caret-right.svg';

import {DongleListResponse} from "../../../../../../../../sdk/datagates/types/dongle/_crud/list";
import {RedirectionListResponse} from "../../../../../../../../sdk/datagates/types/redirection/_crud/list";
import {formatDate} from "../../../../../../../../shared/lib/utils/date-utils/formatDate";
import {isNumber} from "lodash";
import styles from "./styles.module.scss";
import {MODAL_TYPES} from "../../../../../../../../layouts/modals/ModalsProvider";
import {useModal} from "../../../../../../../../app/hooks/useModal";
import {Counter} from "../../../../../../../../shared/ui-kit-2/data-display/counter/Counter";
import {red} from "@mui/material/colors";

interface CallTableProps {
  devices: DongleListResponse['dongles']
  redirections: RedirectionListResponse['redirections']
  totalCount: number
  page: number
  setPage: Dispatch<SetStateAction<number>>
  setLimit: Dispatch<SetStateAction<number>>
  pageLimit: number
  loading: boolean
  handleDelete: (redirectionId: number) => void
  handleEdit: (redirection: RedirectionListResponse['redirections'][0]) => void
}

type RedirectionGroupsType = {
  [key: string]: RedirectionListResponse['redirections']
}

export const CallNotificationsTable: FC<CallTableProps> = (props) => {
  const {
    devices,
    redirections,
    page,
    setPage,
    setLimit,
    pageLimit,
    totalCount,
    loading,
    handleDelete,
    handleEdit,
  } = props

  const intl = useIntl();

  const formatDateToTableHeader = (dateString: string): string => {
    const [day, month, year] = dateString.split('.');
    const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'long', year: 'numeric' };
    const formattedDate = new Date(`${year}-${month}-${day}`).toLocaleDateString('en-GB', options);
    return formattedDate.replace(',', ''); // Убираем запятую из формата
  }

  const handlePageChange = (page: number, limit: number) => {
    setPage(page)
    setLimit(limit)
  }

  const cols: TableColType[] = [
    { title: intl.formatMessage(CommonTableMessages.columnTime) },
    { title: intl.formatMessage(CommonTableMessages.columnNotificationName) },
    { title: intl.formatMessage(CommonTableMessages.columnModems) },
    { title: intl.formatMessage(CommonTableMessages.columnNumbers) },
    { title: <ArrowRightIcon />, position: 'center' },
    { title: intl.formatMessage(CommonTableMessages.columnEmail) },
    { title: intl.formatMessage(CommonTableMessages.columnTelegram) },
    {
      title: intl.formatMessage(CommonTableMessages.columnActions),
      position: 'right',
    },
  ];

  const redirectionGroups = useMemo(() =>
    redirections.reduce<RedirectionGroupsType>((acc, redirection) => {
      const date = formatDate(redirection.createdAt, 0, 'date')

      if (acc.hasOwnProperty(date))
        acc[date as keyof typeof acc].push(redirection)
      else
        acc[date as keyof typeof acc] = [redirection]

      return acc
    }, {})
  , [redirections]);

  const rowGroups = useMemo(() => {
    return Object.entries(redirectionGroups).map<RowGroupType>(([date, redirections]) => {
      return {
        groupHeader: (
          <div className={styles.Header}>
            {formatDateToTableHeader(date)}
          </div>
        ),
        rows: redirections.map((redirection) => {
          return [
            <div className={styles.Time}>
              {formatDate(redirection.createdAt, 0, 'time')}
            </div>,
            <div>
              { redirection.name }
            </div>,
            <div className={styles.Counter}>
              <Counter count={redirection.deviceIds.length} variant='filled' />
            </div>,
            <div className={styles.Counter}>
              <Counter count={redirection.fromContactIds.length} variant='filled' />
            </div>,
            <ArrowRightIcon />,
            <div className={styles.Counter}>
              <Counter count={redirection.toEmails.length} variant='filled' />
            </div>,
            <div className={styles.Counter}>
              <Counter count={redirection.toTelegramChatIds.length} variant='filled' />
            </div>,
            <div className={styles.Actions}>
              <div className={styles.Action} tabIndex={0} onClick={() => handleDelete(redirection.redirectionId)}>
                <TrashIcon />
              </div>
              <div className={styles.Action} tabIndex={0} onClick={() => handleEdit(redirection)}>
                <CaretRightIcon />
              </div>
            </div>,
          ]
        }),
      }
    })
  }, [redirectionGroups]);

  return (
    <Table
      cols={cols}
      rowGroups={rowGroups}
      currentPage={page}
      totalCount={totalCount}
      onPageChange={handlePageChange}
      itemsPerPage={pageLimit}
      loading={loading}
      columnWidths={['100px', 1.5, 1, 1, '58px', 1, 1, 1]}
      noDataComponent={devices.length > 0 ? null : <NoDevicesForCalls />}
    />
  )
}