import React, { FC } from 'react'
import { DongleListResponse } from '../../../../../../sdk/datagates/types/dongle/_crud/list'
import { UpdateWifiFormType } from '../../../../../../sdk/hooks/use-wifi/useWifi'
import { LC } from '../../../../../../tests/e2e/locators'
import { WifiCard } from '../wifi-card/WifiCard'
import styles from './styles.module.scss'
import {ActionCard} from "../../../../../../shared/ui-kit-2/data-display/action-card/ActionCard";
import {isAction} from "@reduxjs/toolkit";
import {MODAL_TYPES} from "../../../../../../layouts/modals/ModalsProvider";
import {useModal} from "../../../../../../app/hooks/useModal";
import {useIntl} from "react-intl";
import {MainMessages} from "../../../../../../shared/intl-messages/MainMessages";
import {
  DeviceDetailsModalMessages
} from "../../../../../../layouts/modals/DeviceDetailsModal/messages/DeviceDetailsModalMessages";

interface WifiCardListProps {
  devices: DongleListResponse['dongles']
  handleUpdateWifi: (values: UpdateWifiFormType) => Promise<void>
  handleSwitchHotspot: (dongleId: number, enabled: boolean) => Promise<void>
}



export const WifiCardList: FC<WifiCardListProps> = (props) => {
  const { devices, handleUpdateWifi, handleSwitchHotspot, } = props
  const { handleOpenModal } = useModal()
  const intl = useIntl()

  const onEdit = (dongleId: number, name: string, hotspotName: string, hotspotPassword: string) => {
    handleOpenModal({
      type: MODAL_TYPES.WIFI_SETTINGS,
      props: {
        handleSubmit: handleUpdateWifi,
        deviceId: dongleId,
        deviceName: name,
        hotspotName: hotspotName,
        hotspotPassword: hotspotPassword,
      },
    })
  }

  return (
    <div className={styles.Cards} data-test-id={LC.WIFI.CARDS}>
      {devices.map(({ dongleId, name, hotspotName, hotspotPassword, isHotspotEnable, phoneNumber, isOnline }) => (
        <ActionCard
          id={dongleId}
          name={name}
          number={phoneNumber}
          settings={{
            onClick: () => onEdit(dongleId, name, hotspotName, hotspotPassword)
          }}
          switcher={{
            currentValue: isHotspotEnable,
            leftButton: {
              text: intl.formatMessage(MainMessages.On),
              onClick: id => handleSwitchHotspot(id, true),
            },
            rightButton: {
              text: intl.formatMessage(MainMessages.Off),
              onClick: id => handleSwitchHotspot(id, false)
            },
          }}
          copyBlock={{
            show: true,
            items: [
              {
                name: intl.formatMessage(DeviceDetailsModalMessages.wifiName),
                value: hotspotName,
                possiblyToCopy: true
              },
              {
                name: intl.formatMessage(MainMessages.Password),
                value: hotspotPassword,
                possiblyToCopy: true
              }
            ]
          }}
          alert={{
            show: !isOnline,
            text: 'Please connect the modem to the network'
          }}
        />
        /*<WifiCard
          key={device.dongleId}
          device={device}
          handleUpdateWifi={handleUpdateWifi}
          handleSwitchHotspot={handleSwitchHotspot}
        />*/
      ))}
    </div>
  )
}
