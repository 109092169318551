import { TermsOfUsePage } from '../../../../../pages/_common/terms-of-use/TermsOfUsePage'
import { RoutePath } from '../../../constants/routePath'
import { Route } from '../../../enums/route'
import { RouteDescriptor } from '../../../types/routeDescriptor'

export const termsOfUse: RouteDescriptor<Route.TermsOfUse> = {
  route: Route.TermsOfUse,
  type: 'page',
  title: 'Teleleo',
  path: RoutePath[Route.TermsOfUse],
  render: TermsOfUsePage,
  accessLevel: 'all',
  noAccessRedirectPath: '/',
}
