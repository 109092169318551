import { ReactComponent as AlertIcon } from '../../../../../assets/icons/alert.svg';
import { ReactComponent as RepeatIcon } from '../../../../../assets/icons/repeat.svg';
import { ReactComponent as RequestIcon } from '../../../../../assets/icons/request.svg';
import { ReactComponent as ToDownIcon } from '../../../../../assets/icons/to-down.svg';
import { ReactComponent as CheckIcon } from '../../../../../assets/icons/check.svg';
import { ReactComponent as DoubleCheckIcon } from '../../../../../assets/icons/double-check.svg';
import { ReactComponent as HistoryIcon } from '../../../../../assets/icons/history.svg';
import clsx from 'clsx';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Button} from "../../../../../shared/ui-kit-2/inputs/button/Button";
import {useUssd} from "../../../../../sdk/hooks/use-ussd/useUssd";
import {InputField} from "../../../../../shared/lib/form/form-field-adapters/v2/input-field/InputField";
import {Form} from "react-final-form";
import {DongleGetResponse} from "../../../../../sdk/datagates/types/dongle/_crud/get";
import {formatDate} from "../../../../../shared/lib/utils/date-utils/formatDate";
import styles from './styles.module.scss';
import {useDialog} from "../../../../../app/hooks/useDialog";
import {DialogVersion} from "../../../../../store/reducers/dialog/types";

const USSD_STATUSES = {
  CREATED: 1,
  SEND: 2,
  RECEIVED: 3,
  NOT_RECEIVED: 4
};

const MAX_USSD_LENGTH = 200;

export const Ussd = ({ device }: { device: DongleGetResponse }) => {
  const {
    messages,
    getMessages,
    deleteMessages,
    sendMessage,
    loadingGetMessages,
    loadingDeleteMessages,
    loadingSendMessage,
    errorGetMessages,
    errorDeleteMessages,
    errorSendMessage
  } = useUssd();
  const { handleOpenDialog, handleHideDialog } = useDialog();

  const [showScrollButton, setShowScrollButton] = useState<boolean>(false);
  const [newMessagesCount, setNewMessagesCount] = useState<number>(0);
  const [scrollingBlocked, setScrollingBlocked] = useState<boolean>(false);
  const [lockedTimeout, setLockedTimeout] = useState<number>(0);

  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const messagesRef = useRef<HTMLDivElement | null>(null);

  const groupedMessagesByDate = useMemo(() => {
    if (messages) {
      const currentYear = new Date().getFullYear();

      const grouped = messages.reduce((acc, message) => {
        const date = new Date(message.created_at);
        const year = date.getFullYear();
        const formattedDate =
          `${date.getDate()} ${date.toLocaleDateString('en-US', {
            month: 'long',
          })}` + (year !== currentYear ? ` ${year}` : '');

        if (!acc[formattedDate]) {
          acc[formattedDate] = {
            date: formattedDate,
            timestamp: date.getTime(),
            messages: [],
          };
        }

        acc[formattedDate].messages.push(message);
        return acc;
      }, {});

        return Object.values(grouped).sort((a: any, b: any) => a.timestamp - b.timestamp);
    }

    return [];
  }, [messages]);

  useEffect(() => {
    scrollToBottom(true);
  }, [messages])

  const scrollToBottom = (instant?: boolean) => {
    setScrollingBlocked(true);
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: instant ? 'auto' : 'smooth' });
      setNewMessagesCount(0);
      setTimeout(() => setScrollingBlocked(false), 100);
    }, 0);
  };

  useEffect(() => {
    const fetchMessages = () => getMessages({ deviceId: device.dongleId });
    fetchMessages();

    const intervalId = setInterval(fetchMessages, 10000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollingBlocked) return;

      if (messagesRef.current) {
        const isScrolledUp =
          messagesRef.current.scrollTop < messagesRef.current.scrollHeight - messagesRef.current.clientHeight - 50;

        setShowScrollButton(isScrolledUp);
        if (!isScrolledUp) {
          setNewMessagesCount(0);
        }
      }
    };

    const container = messagesRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrollingBlocked]);

  useEffect(() => {
    if (lockedTimeout > 0) {
      const intervalId = setInterval(() => {
        setLockedTimeout(prev => {
          if (prev > 1) {
            return prev - 1;
          } else {
            clearInterval(intervalId);
            return 0;
          }
        });
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [lockedTimeout]);

  useEffect(() => {
    if (errorSendMessage) {
      setLockedTimeout(0);
    }
  }, [errorSendMessage]);

  const isAtBottom = () => {
    if (!messagesRef.current) return false;
    const threshold = 50;
    return (
      messagesRef.current.scrollHeight - messagesRef.current.scrollTop - messagesRef.current.clientHeight < threshold
    );
  };

  // TODO: Need import global formatTime method
  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
  }

  const handleAddMessage = async ({ message }: { message: string }) => {
    await sendMessage({ deviceId: device.dongleId, message });
    setLockedTimeout(59);
    scrollToBottom();
  };

  const handleDeleteHistory = () => {
    handleOpenDialog({
      version: DialogVersion.v2,
      props: {
        title: 'Delete all history?',
        subtitle: 'This action cannot be undone',
        cancel: {
          text: 'Cancel',
          onClick: () => handleHideDialog()
        },
        submit: {
          icon: <HistoryIcon />,
          text: 'Delete',
          onClick: () => {
            handleHideDialog();
            deleteMessages({ deviceId: device.dongleId });
          }
        },
      }
    })
  }

  const NoMessages = () => (
    <div className={styles.Event}>
      <div className={clsx(styles.Event__Icon, styles.Event__Icon_green)}>
        <RequestIcon />
      </div>
      <div className={styles.Event__Title}>
        USSD requests
      </div>
      <div className={styles.Event__Subtitle}>
        Enter a query to get information from the operator
      </div>
    </div>
  );

  const USSDAreNotAvailable = () => (
    <div className={styles.Event}>
      <div className={clsx(styles.Event__Icon, styles.Event__Icon_red)}>
        <AlertIcon />
      </div>
      <div className={styles.Event__Title}>
        USSD requests are not available
      </div>
      <div className={styles.Event__Subtitle}>
        You are currently unable to send USSD requests, please contact your operator to fix the problem or wait a few minutes and try again
      </div>
    </div>
  );

  const Messages = () => (
    <>
      <div className={styles.HiddenMessage} />
      {/* @ts-ignore*/}
      {groupedMessagesByDate.map(({ date, messages }) => (
        <>
          <div className={styles.Date}>
            {date}
          </div>
          {/* @ts-ignore*/}
          {messages.map(({ command, created_at, response, ussd_status_id }, index) => (
            <div key={index} className={clsx(styles.Message, command && styles.Message_outgoing)}>
              {command && (
                <button
                  className={styles.Message__Repeat}
                  onClick={() => handleAddMessage(command)}
                  disabled={lockedTimeout > 0}
                >
                  <RepeatIcon />
                </button>
              )}
              <div className={styles.Message__Container}>
                <div className={styles.Message__Text}>
                  {command || response}
                </div>
                <div className={styles.Message__Info}>
                  <div className={styles.Message__Time}>
                    {formatDate(created_at, 0, 'time')}
                  </div>
                  {command && ussd_status_id !== USSD_STATUSES.NOT_RECEIVED && (
                    <div className={styles.Message__Status}>
                      {[USSD_STATUSES.CREATED, USSD_STATUSES.SEND].includes(ussd_status_id) && (
                        <CheckIcon />
                      )}
                      {ussd_status_id === USSD_STATUSES.RECEIVED && (
                        <DoubleCheckIcon />
                      )}
                    </div>
                  )}
                </div>
              </div>
              {command && ussd_status_id === USSD_STATUSES.NOT_RECEIVED && (
                <div className={styles.Message__Alert}>
                  <AlertIcon />
                </div>
              )}
            </div>
          ))}
        </>
      ))}

      <div ref={messagesEndRef} />
    </>
  );

  return (
    <div className={styles.Ussd}>
      <div className={styles.Chat}>
        <div className={styles.Top}>
          <div className={styles.DeviceInfo}>
            <div className={styles.DeviceName}>
              {device.name}
            </div>
            <div className={styles.DevicePhone}>
              <div className={styles.DevicePhone__Label}>
                Phone number:
              </div>
              <div className={styles.DevicePhone__Number}>
                {device.phoneNumber}
              </div>
            </div>
          </div>
          {messages && messages.length > 0 && (
            <Button
              text='Delete'
              icon='history'
              variant='blackTextBlackBorderOutlined'
              onClick={handleDeleteHistory}
              additionalClassNames={[styles.Button]}
              loading={loadingDeleteMessages}
              disabled={loadingDeleteMessages}
            />
          )}
        </div>
        <div
          className={clsx(styles.Messages, (messages?.length === 0 || errorGetMessages) && styles.Messages_event)}
          ref={messagesRef}
        >
          {
            messages?.length === 0
              ? <NoMessages />
              : errorGetMessages
                ? <USSDAreNotAvailable />
                : <Messages />
          }
        </div>
        <Form
          onSubmit={async (values) => {
            await handleAddMessage(values as { message: string });
          }}
          render={({ form, handleSubmit, submitting, values }) => (
            <form
              onSubmit={
                async (...args) => {
                  await handleSubmit(...args);
                  form.reset();
                }
              }
              className={styles.Content}
            >
              <div className={styles.Bottom}>
                <div className={styles.Input}>
                  <InputField
                    name="message"
                    placeholder={lockedTimeout > 0
                      ? `The message can be sent via ${formatTime(lockedTimeout)}`
                      : 'Enter the USSD request in the format *NNN#'
                    }
                    postfix={`${!values?.message ? 'Max' : 'Left'} ${MAX_USSD_LENGTH - (values?.message?.length || 0)} ${values?.message?.length === MAX_USSD_LENGTH - 1 ? 'character' : 'characters'}`}
                    showErrorText={false}
                    showSuccessValidationIcon={false}
                    maxLength={MAX_USSD_LENGTH}
                    disabled={errorGetMessages || lockedTimeout > 0}
                  />
                </div>
                <Button
                  type='submit'
                  text='Send'
                  icon='send'
                  variant='green'
                  additionalClassNames={[styles.Button]}
                  loading={loadingDeleteMessages || loadingSendMessage}
                  disabled={loadingDeleteMessages || loadingSendMessage || errorGetMessages || lockedTimeout > 0 || !values?.message}
                />
              </div>
            </form>
          )}
        />
        {showScrollButton && (
          <button
            className={styles.ToDownButton}
            onClick={() => scrollToBottom()}
          >
            <ToDownIcon />
            {newMessagesCount > 0 && <span className={styles.NewMessagesCount}>{newMessagesCount}</span>}
          </button>
        )}
      </div>
    </div>
  );
};
