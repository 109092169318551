import { PurchaseProcessPage } from '../../../../../../../pages/lk/subpages/purchase-process/PurchaseProcessPage'
import { AuthRoutePath } from '../../../../../constants/authRoutePath'
import { RoutePath } from '../../../../../constants/routePath'
import { AuthRoute } from '../../../../../enums/authRoute'
import { Route } from '../../../../../enums/route'
import { RouteDescriptor } from '../../../../../types/routeDescriptor'

export const purchaseProcess: RouteDescriptor<Route.PurchaseProcess> = {
  route: Route.PurchaseProcess,
  type: 'subpage',
  title: 'Teleleo',
  path: RoutePath[Route.PurchaseProcess],
  render: PurchaseProcessPage,
  accessLevel: 'user',
  noAccessRedirectPath: AuthRoutePath[AuthRoute.SignIn],
}
