import { PrivacyPolicyPage } from '../../../../../pages/_common/privacy-policy/PrivacyPolicyPage'
import { RoutePath } from '../../../constants/routePath'
import { Route } from '../../../enums/route'
import { RouteDescriptor } from '../../../types/routeDescriptor'

export const privacyPolicy: RouteDescriptor<Route.PrivacyPolicy> = {
  route: Route.PrivacyPolicy,
  type: 'page',
  title: 'Teleleo',
  path: RoutePath[Route.PrivacyPolicy],
  render: PrivacyPolicyPage,
  accessLevel: 'all',
  noAccessRedirectPath: '/',
}
