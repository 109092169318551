import React, { useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import { useLocation, useNavigate } from 'react-router'
import { AuthRoutePath } from '../../../../config/routes/constants/authRoutePath'
import { RoutePath } from '../../../../config/routes/constants/routePath'
import { AuthRoute } from '../../../../config/routes/enums/authRoute'
import { Route } from '../../../../config/routes/enums/route'
import {
  HomeSectionAnchors,
  HomeSectionIds,
} from '../../../../pages/home/constants/section-anchors/SectionAnchors'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../../../shared/lib/hooks/useLessThanDeviceScreen'
import { LC } from '../../../../tests/e2e/locators'
import { GuestHeaderMenuMobile } from './components/guest-header-menu-mobile/GuestHeaderMenuMobile'
import { GuestHeaderMenu } from './components/guest-header-menu/GuestHeaderMenu'
import styles from './styles.module.scss'
import {useRootPath} from "../../../../shared/lib/hooks/useRootPath";
import {Logo} from "../../../../shared/ui-kit-2/navigation/logo/Logo";

const headerHeight = 73
let lastScrollPos = 0
const HeaderNotShownRoutePathGroup = [
  AuthRoute.SignIn,
  AuthRoute.SignUp,
  AuthRoute.RecoverPassword,
].map((route) => AuthRoutePath[route])

export const GuestHeader = () => {
  const [isDown, setIsDown] = useState<boolean>(true)
  const [isOnTop, setIsOnTop] = useState<boolean>(true)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isLessThanLaptop = useLessThanDeviceScreen(ScreenDevices.LAPTOP)
  const rootPath = useRootPath()
  let homePage = document.getElementById(HomeSectionIds.ALL_SECTIONS)

  const isHeaderHidden = useMemo(
    () => HeaderNotShownRoutePathGroup.some((path) => path === pathname),
    [pathname],
  )

  const onScroll = (e: Event) => {
    setIsOnTop(window.scrollY <= headerHeight)
    setIsDown(window.scrollY > lastScrollPos)
    lastScrollPos = window.scrollY
  }

  const onHomeScroll = (e: Event) => {
    if (homePage) {
      setIsOnTop(homePage.scrollTop <= headerHeight)
      setIsDown(homePage.scrollTop > lastScrollPos)
      lastScrollPos = homePage.scrollTop
    }
  }

  const isMobileHeaderHidden = useMemo(
    () => HeaderNotShownRoutePathGroup.some((path) => path === pathname),
    [pathname],
  )

  useEffect(() => {
    if (HeaderNotShownRoutePathGroup.some((path) => path === pathname)) {
      setIsDown(true)
      setIsOnTop(true)
      return
    }

    setIsDown(false)
    setIsOnTop(true)

    if (pathname === RoutePath[Route.Home] && !isLessThanLaptop) {
      homePage = document.getElementById(HomeSectionIds.ALL_SECTIONS)
      homePage?.addEventListener('scroll', onHomeScroll, true)
      window.removeEventListener('scroll', onScroll)
    } else {
      window.addEventListener('scroll', onScroll, true)
      homePage?.removeEventListener('scroll', onHomeScroll)
    }

    return () => {
      homePage?.removeEventListener('scroll', onHomeScroll, true)
      window.removeEventListener('scroll', onScroll)
    }
  }, [pathname])

  const toHome = () => navigate(rootPath);

  return (
    <div
      className={clsx(
        styles.Header,
        isOnTop && styles.Header_onTop,
        isHeaderHidden && styles.Header_hidden,
      )}
      data-test-id={LC.HEADER._}
    >
      <div className={styles.Content}>
        <a
          href={HomeSectionAnchors.TOP}
          onClick={toHome}
          className={styles.Logo}
        >
          <Logo />
        </a>

        {isLessThanLaptop ? (
          <GuestHeaderMenuMobile />
        ) : (
          <GuestHeaderMenu isHeaderOnTop={isOnTop} />
        )}
      </div>
    </div>
  )
}
