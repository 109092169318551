import { RecoverPasswordPage } from '../../../../../../../pages/auth/subpages/recover-password/RecoverPasswordPage'
import { AuthRoutePath } from '../../../../../constants/authRoutePath'
import { RoutePath } from '../../../../../constants/routePath'
import { AuthRoute } from '../../../../../enums/authRoute'
import { Route } from '../../../../../enums/route'
import { RouteDescriptor } from '../../../../../types/routeDescriptor'

export const recoverPassword: RouteDescriptor<AuthRoute.RecoverPassword> = {
  route: AuthRoute.RecoverPassword,
  type: 'subpage',
  title: 'Teleleo',
  path: AuthRoutePath[AuthRoute.RecoverPassword],
  render: RecoverPasswordPage,
  accessLevel: 'guest',
  noAccessRedirectPath: RoutePath[Route.DeviceList],
}
