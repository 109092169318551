import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useSiteVersion } from '../../../app/hooks/useSiteVersion'
import noDevicesImg from '../../../assets/images/no-devices-for-calls.png'
import { BuyDeviceButton } from '../../custom-buttons/buy-device-button/BuyDeviceButton'
import styles from './styles.module.scss'

const DeviceTableNoDataMessages = defineMessages({
  noData: {
    id: 'DeviceTableNoDataMessages.noData',
    defaultMessage: "You don't have any Teleleo modem yet",
  },
  button: {
    id: 'DeviceTableNoDataMessages.button',
    defaultMessage: 'Buy Device',
  },
})

export const NoDevices = () => {
  const intl = useIntl()
  const { siteVersion } = useSiteVersion()

  return (
    <div className={styles.NoData}>
      <div className={styles.Label}>
        {intl.formatMessage(DeviceTableNoDataMessages.noData)}
      </div>
      {siteVersion !== 'ru' && (
        <BuyDeviceButton additionalClassNames={[styles.Button]} />
      )}

      <img src={noDevicesImg} alt="Devices" className={styles.Image} />
    </div>
  )
}
