export enum DialogVersion {
  "v1",
  "v2"
}

export interface DialogType {
  isOpen: boolean
  version?: DialogVersion
  props?: any
}
