
import * as React from "react";
import { useSiteVersion } from "../../../../app/hooks/useSiteVersion";
import { ReactComponent as EnLogoIcon } from '../../../../assets/icons/v2/ic-en-logo.svg'
import { ReactComponent as RuLogoIcon } from '../../../../assets/icons/v2/ic-ru-logo.svg'

export const Logo = (props: React.SVGProps<SVGSVGElement>) => {
  const { siteVersion } = useSiteVersion();

  if (siteVersion === 'ru') {
    return (
      <RuLogoIcon {...props} />
    )
  }

  return (
    <EnLogoIcon {...props} />
  )
}