import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {DialogType, DialogVersion} from './types'

const dialogInitialState: DialogType = {
  isOpen: false,
  version: DialogVersion.v1,
  props: {},
}

const dialogSlice = createSlice({
  name: 'dialog',
  initialState: dialogInitialState,
  reducers: {
    setDialog(state, { payload }: PayloadAction<DialogType>) {
      state.isOpen = payload.isOpen
      state.version = payload?.version || DialogVersion.v1
      state.props = payload?.props || {}
    },
    clearDialog(state) {
      state.isOpen = false
      state.version = DialogVersion.v1
      state.props = {}
    },
  },
})

export const { setDialog, clearDialog } = dialogSlice.actions
export default dialogSlice.reducer
