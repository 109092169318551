import React, { useCallback, useContext, useState } from 'react'
import clsx from 'clsx'
import { defineMessages, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { useModal } from '../../../../../../app/hooks/useModal'
import { useSiteVersion } from '../../../../../../app/hooks/useSiteVersion'
import { useSnackbar } from '../../../../../../app/hooks/useSnackbar'
import { CommonButtonMessages } from '../../../../../../config/intl/common-messages/common-button-messages/CommonButtonMessages'
import { RoutePath } from '../../../../../../config/routes/constants/routePath'
import { Route } from '../../../../../../config/routes/enums/route'
import { MODAL_TYPES } from '../../../../../../layouts/modals/ModalsProvider'
import { SNACKBAR_TYPES } from '../../../../../../layouts/snackbar/SnackbarProvider'
import { orderCreateDatagate } from '../../../../../../sdk/datagates/api/order'
import { useBackendErrorCodes } from '../../../../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import { TARIFF_PACKAGE_ID } from '../../../../../../sdk/datagates/types/order/_common'
import { formatPrice } from '../../../../../../sdk/formatters/format-price'
import { ORDER_TYPES } from '../../../../../../sdk/hooks/use-order/useOrder'
import { useTariffPackages } from '../../../../../../sdk/hooks/use-tariff-packages/useTariffPackages'
import { getErrorMessage } from '../../../../../../shared/lib/utils/get-error-message/getErrorMessage'
import { Button } from '../../../../../../shared/ui-kit-2/inputs/button/Button'
import { TextButton } from '../../../../../../shared/ui-kit-2/inputs/text-button/TextButton'
import { PaymentMethodCard } from '../../../purchase-process/components/purchase-step-payment/components/PaymentMethodCard/PaymentMethodCard'
import { BankCardPaymentMethod } from '../../../purchase-process/components/purchase-step-payment/components/paymentsMethods/BankCardPaymentMethod/BankCardPaymentMethod'
import { LegalEntitiesPaymentMethod } from '../../../purchase-process/components/purchase-step-payment/components/paymentsMethods/LegalEntitiesPaymentMethod/LegalEntitiesPaymentMethod'
import { BalancePaymentMethod } from '../../../purchase-process/components/purchase-step-payment/components/paymentsMethods/TelecornBalancePaymentMethod/BalancePaymentMethod'
import { PaymentMethods } from '../../../purchase-process/components/purchase-step-payment/PurchaseStepPayment'
import {
  ACTIVATE_DEVICE_STEPS,
  ActivateDeviceContext,
} from '../../contexts/ActivateDeviceContext'
import styles from './styles.module.scss'

const StepSubscriptionPaymentMessages = defineMessages({
  subscriptionTypeTitle: {
    id: 'StepSubscriptionPaymentMessages.subscriptionTypeTitle',
    defaultMessage: 'Choose subscription type',
  },
  paymentMethodTitle: {
    id: 'StepSubscriptionPaymentMessages.paymentMethodTitle',
    defaultMessage: 'Choose payment method',
  },
  paymentMethodDescription: {
    id: 'StepSubscriptionPaymentMessages.paymentMethodDescription',
    defaultMessage: 'At the moment only card payments are available',
  },
  bankCard: {
    id: 'StepSubscriptionPaymentMessages.bankCard',
    defaultMessage: 'Bank card',
  },
  telecornBalance: {
    id: 'StepSubscriptionPaymentMessages.telecornBalance',
    defaultMessage: 'Teleleo balance',
  },
  paymentByDetails: {
    id: 'StepSubscriptionPaymentMessages.paymentByDetails',
    defaultMessage: 'Payment by details',
  },
  annualGift: {
    id: 'StepSubscriptionPaymentMessages.annualGift',
    defaultMessage: '+ 2 months for free!',
  },
  later: {
    id: 'StepSubscriptionPaymentMessages.later',
    defaultMessage: 'Later',
  },
  payNow: {
    id: 'StepSubscriptionPaymentMessages.payNow',
    defaultMessage: 'Pay now',
  },
  subscriptionType: {
    id: 'StepSubscriptionPaymentMessages.subscriptionType',
    defaultMessage: '{type} subscription',
  },
  existingCardPaySuccess: {
    id: 'StepSubscriptionPaymentMessages.existingCardPaySuccess',
    defaultMessage: 'Order is successfully paid',
  },
  subscriptionDescription: {
    id: 'StepSubscriptionPaymentMessages.subscriptionDescription',
    defaultMessage:
      'Due to the fact that the product includes functionality for working with SMS, to improve the service, the company periodically checks this functionality, including in the operator’s network by sending a service SMS to the subscriber’s number in the background.\n' +
      'The operation of SIM cards with tariffs for smartphones depends on the rules of the mobile operator. You may need to use a modem tariff.',
  },
})

export const StepSubscriptionPayment = () => {
  const { imeiLastSixDigits, setCurrentStep } = useContext(
    ActivateDeviceContext,
  )

  const intl = useIntl()
  const navigate = useNavigate()
  const { handleOpenSnackbar } = useSnackbar()
  const { handleOpenModal } = useModal()
  const { resolveBackendError } = useBackendErrorCodes()
  const { getTariffPackageTranslation, getTariffPackagePrice } =
    useTariffPackages()
  const [tariffPackageId, setTariffPackageId] = useState<number>(
    TARIFF_PACKAGE_ID.MONTH,
  )
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethods>(
    PaymentMethods.CARD,
  )
  const [loading, setLoading] = useState<boolean>(false)
  const { siteVersion } = useSiteVersion()

  const toDevices = () => navigate(RoutePath[Route.DeviceList])

  const backToConnectStep = () => setCurrentStep(ACTIVATE_DEVICE_STEPS.CONNECT)

  const createOrderCallback = useCallback(async () => {
    return await orderCreateDatagate({
      order_type_id: ORDER_TYPES.SUBSCRIPTION_ONLY,
      order_dongles: [
        {
          tariff_package_id: tariffPackageId,
          count: 1,
          imei_manufacture: imeiLastSixDigits,
        },
      ],
      first_name: '-',
      last_name: '-',
      billing_email: 'subscription@teleleo.com',
      country_id: 1,
      phone: '-',
      street_address1: '-',
      postal_code: '-',
      delivery_provider_id: 1
    })
  }, [tariffPackageId, imeiLastSixDigits])

  const onSubmit = async () => {
    try {
      setLoading(true)

      handleOpenModal({
        type: MODAL_TYPES.PAYMENT_BANK_CARD_CHOOSE,
        props: {
          onExistingCardPaymentSuccess: () => {
            navigate(RoutePath[Route.DeviceList])
          },
          createOrderCallback: createOrderCallback,
        },
      })
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(getErrorMessage(e)),
      })
    } finally {
      setLoading(false)
    }
  }

  const monthSubscriptionTypeTitle = intl.formatMessage(
    StepSubscriptionPaymentMessages.subscriptionType,
    {
      type: getTariffPackageTranslation(TARIFF_PACKAGE_ID.MONTH),
    },
  )

  const annualSubscriptionTypeTitle = intl.formatMessage(
    StepSubscriptionPaymentMessages.subscriptionType,
    {
      type: getTariffPackageTranslation(TARIFF_PACKAGE_ID.ANNUAL),
    },
  )

  return (
    <div className={styles.Container}>
      <div className={styles.Section}>
        <div className={styles.SectionHeader}>
          <div className={styles.SectionHeader__title}>
            {intl.formatMessage(
              StepSubscriptionPaymentMessages.subscriptionTypeTitle,
            )}
          </div>
        </div>

        <div className={styles.SectionCards}>
          <div className={styles.SubscriptionTypeCardContainer}>
            <PaymentMethodCard
              title={monthSubscriptionTypeTitle}
              setPaymentMethod={setTariffPackageId}
              paymentMethodType={TARIFF_PACKAGE_ID.MONTH}
              currentMethodType={tariffPackageId}
              children={
                <div className={styles.SubscriptionTypeContent}>
                  <div>
                    {formatPrice(
                      getTariffPackagePrice(TARIFF_PACKAGE_ID.MONTH),
                    )}
                  </div>
                </div>
              }
            />
          </div>
          <div className={styles.SubscriptionTypeCardContainer}>
            <PaymentMethodCard
              title={annualSubscriptionTypeTitle}
              setPaymentMethod={setTariffPackageId}
              paymentMethodType={TARIFF_PACKAGE_ID.ANNUAL}
              currentMethodType={tariffPackageId}
              children={
                <div className={styles.SubscriptionTypeContent}>
                  <div>
                    {formatPrice(
                      getTariffPackagePrice(TARIFF_PACKAGE_ID.ANNUAL),
                    )}
                  </div>
                  <span>
                    {intl.formatMessage(
                      StepSubscriptionPaymentMessages.annualGift,
                    )}
                  </span>
                </div>
              }
            />
          </div>
        </div>
      </div>

      <div className={styles.Section}>
        <div className={styles.SectionHeader}>
          <div className={styles.SectionHeader__title}>
            {intl.formatMessage(
              StepSubscriptionPaymentMessages.paymentMethodTitle,
            )}
          </div>
          <div className={styles.SectionHeader__description}>
            {intl.formatMessage(
              StepSubscriptionPaymentMessages.paymentMethodDescription,
            )}
          </div>
        </div>

        <div className={styles.SectionCards}>
          <div className={styles.PaymentMethodCardContainer}>
            <PaymentMethodCard
              title={intl.formatMessage(
                StepSubscriptionPaymentMessages.bankCard,
              )}
              paymentMethodType={PaymentMethods.CARD}
              children={<BankCardPaymentMethod />}
              currentMethodType={paymentMethod}
              setPaymentMethod={setPaymentMethod}
            />
          </div>

          <div
            className={clsx(
              styles.PaymentMethodCardContainer,
              styles.PaymentMethodCardContainer_disabled,
            )}
          >
            <PaymentMethodCard
              title={intl.formatMessage(
                StepSubscriptionPaymentMessages.telecornBalance,
              )}
              paymentMethodType={PaymentMethods.BALANCE}
              children={<BalancePaymentMethod balanceNotEnoughAmount={null} />}
              currentMethodType={paymentMethod}
              setPaymentMethod={setPaymentMethod}
              disabled={true}
            />
          </div>

          <div
            className={clsx(
              styles.PaymentMethodCardContainer,
              styles.PaymentMethodCardContainer_disabled,
            )}
          >
            <PaymentMethodCard
              title={intl.formatMessage(
                StepSubscriptionPaymentMessages.paymentByDetails,
              )}
              paymentMethodType={PaymentMethods.WIRE_TRANSFER}
              children={<LegalEntitiesPaymentMethod />}
              currentMethodType={paymentMethod}
              setPaymentMethod={setPaymentMethod}
              disabled={true}
            />
          </div>
        </div>
      </div>

      <div className={styles.SectionDescription}>
        {intl.formatMessage(
          StepSubscriptionPaymentMessages.subscriptionDescription,
        )}
      </div>

      <div className={styles.Footer}>
        <TextButton
          text={intl.formatMessage(CommonButtonMessages.back)}
          onClick={backToConnectStep}
          icon={'caret-left'}
        />

        <div className={styles.Footer__actions}>
          <Button
            type={'submit'}
            text={intl.formatMessage(StepSubscriptionPaymentMessages.later)}
            onClick={toDevices}
            variant={'greenTextOutlined'}
            additionalClassNames={[styles.LaterBtn]}
            disabled={loading}
          />
          <Button
            type={'submit'}
            text={intl.formatMessage(StepSubscriptionPaymentMessages.payNow)}
            onClick={onSubmit}
            additionalClassNames={[styles.PayNowBtn]}
            loading={loading}
            disabled={loading}
          />
        </div>
      </div>
    </div>
  )
}
