const FILES = require('./sounds.json');

const SOUNDS = new Map(
	[
		[ 'ringback', { audio: new Audio(FILES['ringback']), volume: 1.0, loop: true } ],
		[ 'ringing', { audio: new Audio(FILES['ringing']), volume: 1.0 } ],
		[ 'answered', { audio: new Audio(FILES['answered']), volume: 1.0 } ],
		[ 'rejected', { audio: new Audio(FILES['rejected']), volume: 0.5 } ]
	]
);

let initialized = false;

module.exports = {
	initialize() {
		if (initialized) return;

		for (const sound of SOUNDS.values()) {
			sound.audio.volume = 0;
			sound.audio.loop = !!sound.loop;
			try {
				sound.audio.play().then(() => sound.audio.pause()).catch(() => {});
			} catch (error) {}
		}

		initialized = true;
	},

	async play(name, relativeVolume = 1.0) {
		this.initialize();

		const sound = SOUNDS.get(name);

		if (!sound) throw new Error(`unknown sound name "${name}"`);

		if (!sound.audio.paused) {
			await sound.audio.pause();
			sound.audio.currentTime = 0.0;
		}

		sound.audio.volume = (sound.volume || 1.0) * relativeVolume;

		try {
			await new Promise(resolve => setTimeout(resolve, 100));
			await sound.audio.play();
		} catch (error) {
			console.error(`Failed to play sound "${name}":`, error);
		}
	},

	stop(name) {
		if (!name) {
			for (const sound of SOUNDS.values()) {
				if (!sound.audio.paused) {
					sound.audio.pause();
					sound.audio.currentTime = 0.0;
				}
			}
		} else {
			const sound = SOUNDS.get(name);
			if (!sound) throw new Error(`unknown sound name "${name}"`);

			if (!sound.audio.paused) {
				sound.audio.pause();
				sound.audio.currentTime = 0.0;
			}
		}
	},

	isPlaying(name) {
		const sound = SOUNDS.get(name);
		return sound && !sound.audio.paused;
	}
};
