import {useDispatch} from "react-redux";
import {clearDialog, setDialog} from "../../store/reducers/dialog";
import {DialogVersion} from "../../store/reducers/dialog/types";

export const useDialog = () => {
  const dispatch = useDispatch()

  const handleOpenDialog = ({ version, props } : { version: DialogVersion, props: Record<string, unknown> }) => {
    dispatch(
      setDialog({
        isOpen: true,
        version,
        props
      })
    )
  }

  const handleHideDialog = () => dispatch(clearDialog())

  return {
    handleOpenDialog,
    handleHideDialog
  }
}