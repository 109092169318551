import { ActivateDevicePage } from '../../../../../../../pages/lk/subpages/activate-device/ActivateDevicePage'
import { AuthRoutePath } from '../../../../../constants/authRoutePath'
import { RoutePath } from '../../../../../constants/routePath'
import { AuthRoute } from '../../../../../enums/authRoute'
import { Route } from '../../../../../enums/route'
import { RouteDescriptor } from '../../../../../types/routeDescriptor'

export const activateDevice: RouteDescriptor<Route.ActivateDevice> = {
  route: Route.ActivateDevice,
  type: 'subpage',
  title: 'Teleleo',
  path: RoutePath[Route.ActivateDevice],
  render: ActivateDevicePage,
  accessLevel: 'user',
  noAccessRedirectPath: AuthRoutePath[AuthRoute.SignIn],
}
