import { OrdersPage } from '../../../../../../../pages/lk/subpages/orders/OrdersPage'
import { AuthRoutePath } from '../../../../../constants/authRoutePath'
import { RoutePath } from '../../../../../constants/routePath'
import { AuthRoute } from '../../../../../enums/authRoute'
import { Route } from '../../../../../enums/route'
import { RouteDescriptor } from '../../../../../types/routeDescriptor'

export const orders: RouteDescriptor<Route.Orders> = {
  route: Route.Orders,
  type: 'subpage',
  title: 'Teleleo',
  path: RoutePath[Route.Orders],
  render: OrdersPage,
  accessLevel: 'user',
  noAccessRedirectPath: AuthRoutePath[AuthRoute.SignIn],
}
