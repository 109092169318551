import { SmsRulesPage } from '../../../../../../../../../pages/lk/subpages/sms/subpages/sms-rules/SmsRulesPage'
import { AuthRoutePath } from '../../../../../../../constants/authRoutePath'
import { SmsRoutePath } from '../../../../../../../constants/smsRoutePath'
import { AuthRoute } from '../../../../../../../enums/authRoute'
import { SmsRoute } from '../../../../../../../enums/smsRoute'
import { RouteDescriptor } from '../../../../../../../types/routeDescriptor'

export const smsRules: RouteDescriptor<SmsRoute.SmsRules> = {
  route: SmsRoute.SmsRules,
  type: 'subpage',
  title: 'Teleleo',
  path: SmsRoutePath[SmsRoute.SmsRules],
  render: SmsRulesPage,
  accessLevel: 'user',
  noAccessRedirectPath: AuthRoutePath[AuthRoute.SignIn],
}
