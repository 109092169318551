import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../../../../shared/lib/hooks/useLessThanDeviceScreen'
import PortableImage from '../../../assets/portable.png'
import PortableRuImage from '../../../assets/portable-ru.png'
import { HomeSectionIds } from '../../../constants/section-anchors/SectionAnchors'
import { Card } from '../../Card/Card'
import styles from './styles.module.scss'
import {useSiteVersion} from "../../../../../app/hooks/useSiteVersion";

export const Home03Messages = defineMessages({
  title: {
    id: 'Home03Messages.title',
    defaultMessage: 'Features',
  },
  hotspotTitle: {
    id: 'Home03Messages.hotspotTitle',
    defaultMessage: 'WiFi Hotspot',
  },
  hotspotSubtitle: {
    id: 'Home03Messages.hotspotSubtitle',
    defaultMessage:
      'Experience rapid 4G LTE internet access, ensuring smooth and reliable connectivity for all your business needs.',
  },
  iotTitle: {
    id: 'Home03Messages.iotTitle',
    defaultMessage: 'IOT Solutions',
  },
  iotSubtitle: {
    id: 'Home03Messages.iotSubtitle',
    defaultMessage:
      "Empower your IoT devices and connect your vehicle with Teleleo's 'Auto-Tools' for seamless, real-time data transmission and improved operational efficiency.",
  },
  pocketTitle: {
    id: 'Home03Messages.pocketTitle',
    defaultMessage: 'Pocket-size hub',
  },
  mobileTitle: {
    id: 'Home03Messages.mobileTitle',
    defaultMessage: 'Mobile Identity Verification',
  },
  mobileSubtitle: {
    id: 'Home03Messages.mobileSubtitle',
    defaultMessage:
      "Teleleo's USB modem offers seamless connectivity and advanced features like call management and mobile identity verification.",
  },
  chatbotTitle: {
    id: 'Home03Messages.chatbotTitle',
    defaultMessage: 'Chatbot/2-Way Communication',
  },
  chatbotSubtitle: {
    id: 'Home03Messages.chatbotSubtitle',
    defaultMessage:
      'Engage customers in real-time through SMS, calls, and chatbots for efficient, interactive support.',
  },
  smsTitle: {
    id: 'Home03Messages.smsTitle',
    defaultMessage: 'SMS/Voice Management',
  },
  smsSubtitle: {
    id: 'Home03Messages.smsSubtitle',
    defaultMessage:
      "Effortlessly manage and forward your SMS and Voice with Teleleo's intuitive tools.",
  },
  apiTitle: {
    id: 'Home03Messages.apiTitle',
    defaultMessage: 'API Integration',
  },
  apiSubtitle: {
    id: 'Home03Messages.apiSubtitle',
    defaultMessage:
      'Connect with customers on the most popular CRM and messaging apps.',
  },
})

export const Home03 = () => {
  const isLessThanTablet = useLessThanDeviceScreen(ScreenDevices.LAPTOP)
  const intl = useIntl()
  const { siteVersion } = useSiteVersion()

  return (
    <div className={styles.Container} id={HomeSectionIds.FEATURES}>
      <div className={styles.Title}>
        {intl.formatMessage(Home03Messages.title)}
      </div>
      <div className={styles.Grid}>
        <div className={styles.Wifi}>
          <Card
            video={'videos/wifi-hotspot.mp4'}
            title={intl.formatMessage(Home03Messages.hotspotTitle)}
            subtitle={intl.formatMessage(Home03Messages.hotspotSubtitle)}
            orientation={'vertical'}
            options={{
              blurredBackground: true,
            }}
            classes={{
              background: styles.Wifi__Background,
            }}
          />
        </div>
        <div className={styles.Iot}>
          <Card
            video={'videos/iot.mp4'}
            title={intl.formatMessage(Home03Messages.iotTitle)}
            subtitle={intl.formatMessage(Home03Messages.iotSubtitle)}
            options={{
              halvingMode: isLessThanTablet,
              blurredBackground: isLessThanTablet,
            }}
            classes={{
              container: styles.CardContainer,
              background: styles.Iot__Background,
              wrapper: styles.CardWrapper,
            }}
          />
        </div>
        {!isLessThanTablet && (
          <div className={styles.Pocket}>
            <Card
              image={siteVersion === 'ru' ? PortableRuImage : PortableImage}
              title={intl.formatMessage(Home03Messages.pocketTitle)}
              orientation={'vertical'}
              options={{
                halvingMode: isLessThanTablet,
                blurredBackground: true,
              }}
              classes={{
                container: styles.CardContainer,
                wrapper: styles.CardWrapper,
              }}
            />
          </div>
        )}
        <div className={styles.Mobile}>
          <Card
            video={'videos/mobile-identity.mp4'}
            title={intl.formatMessage(Home03Messages.mobileTitle)}
            subtitle={intl.formatMessage(Home03Messages.mobileSubtitle)}
            options={{
              halvingMode: isLessThanTablet,
              blurredBackground: isLessThanTablet,
            }}
            classes={{
              container: styles.CardContainer,
              wrapper: styles.CardWrapper,
              background: styles.Mobile__Background,
            }}
          />
        </div>
        <div className={styles.Chatbot}>
          <Card
            video={'videos/chatbot.mp4'}
            title={intl.formatMessage(Home03Messages.chatbotTitle)}
            subtitle={intl.formatMessage(Home03Messages.chatbotSubtitle)}
            orientation={'vertical'}
            options={{
              halvingMode: isLessThanTablet,
              blurredBackground: true,
            }}
            classes={{
              container: styles.CardContainer,
              wrapper: styles.CardWrapper,
              background: styles.Chatbot__Background,
            }}
          />
        </div>
        <div className={styles.Sms}>
          <Card
            video={'videos/sms-voice.mp4'}
            title={intl.formatMessage(Home03Messages.smsTitle)}
            subtitle={intl.formatMessage(Home03Messages.smsSubtitle)}
            options={{
              halvingMode: isLessThanTablet,
              blurredBackground: true,
            }}
            classes={{
              container: styles.CardContainer,
              wrapper: styles.CardWrapper,
              background: styles.Sms__Background,
            }}
          />
        </div>
        <div className={styles.Api}>
          <Card
            video={'videos/auth.mp4'}
            title={intl.formatMessage(Home03Messages.apiTitle)}
            subtitle={intl.formatMessage(Home03Messages.apiSubtitle)}
            orientation={'vertical'}
            options={{
              halvingMode: isLessThanTablet,
              blurredBackground: isLessThanTablet,
            }}
            classes={{
              container: styles.CardContainer,
              wrapper: styles.CardWrapper,
              background: styles.Api__Background,
            }}
          />
        </div>
      </div>
    </div>
  )
}
