import { AuthRoutePath } from '../../../../../../../constants/authRoutePath'
import { AuthRoute } from '../../../../../../../enums/authRoute'
import { RouteDescriptor } from '../../../../../../../types/routeDescriptor'
import {CallsRoutePath} from "../../../../../../../constants/callsRoutePath";
import {CallsRoute} from "../../../../../../../enums/callsRoute";
import {
  CallNotificationsPage
} from "../../../../../../../../../pages/lk/subpages/calls/subpages/calls-notifications/CallNotificationsPage";

export const callsRedirects: RouteDescriptor<CallsRoute.CallsRedirects> = {
  route: CallsRoute.CallsRedirects,
  type: 'subpage',
  title: 'Teleleo',
  path: CallsRoutePath[CallsRoute.CallsRedirects],
  render: CallNotificationsPage,
  accessLevel: 'user',
  noAccessRedirectPath: AuthRoutePath[AuthRoute.SignIn],
}