import { LegalNotice } from '../../../../../pages/_common/legal-notice/LegalNotice'
import { RoutePath } from '../../../constants/routePath'
import { Route } from '../../../enums/route'
import { RouteDescriptor } from '../../../types/routeDescriptor'

export const legalNotice: RouteDescriptor<Route.LegalNotice> = {
  route: Route.LegalNotice,
  type: 'page',
  title: 'Teleleo',
  path: RoutePath[Route.LegalNotice],
  render: LegalNotice,
  accessLevel: 'all',
  noAccessRedirectPath: '/',
}
