import { useEffect, useState } from 'react'
import { getErrorMessage } from '../../../shared/lib/utils/get-error-message/getErrorMessage'
import { dongleGetDatagate } from '../../datagates/api/dongle'
import { useBackendErrorCodes } from '../../datagates/helpers/_common/use-backend-error-codes'
import { DongleGetResponse } from '../../datagates/types/dongle/_crud/get'

type UseDevicesProps = {
  deviceId?: number | null
  noInitialFetch?: boolean
}

export const useDevice = (props: UseDevicesProps) => {
  const { deviceId: propsDeviceId, noInitialFetch } = props
  const { resolveBackendError } = useBackendErrorCodes()
  const [device, setDevice] = useState<DongleGetResponse | undefined>(undefined)
  const [initialLoading, setInitialLoading] = useState<boolean>(!noInitialFetch)
  const [loading, setLoading] = useState<boolean>(!noInitialFetch)
  const [errorText, setErrorText] = useState<string | null>(null)

  const handleFetch = async (deviceId: number, brandId?: number) => {
    setLoading(true)

    try {
      const now = new Date().valueOf();

      const urlParams = [
        { name: 'dongle_id', value: `${deviceId}` }
      ];

      if (brandId) {
        urlParams.push({ name: 'bootstrap_dongle_brand_ids[]', value: `${brandId}` });
      }

      const { data } = await dongleGetDatagate(
        null,
        urlParams
      )
      const waitTime = Math.max(0, -(new Date().valueOf() - now) + 1000)
      await new Promise(resolve => setTimeout(resolve, waitTime))
      setDevice(data)

      return data;
    } catch (e) {
      setErrorText(resolveBackendError(getErrorMessage(e)))
    } finally {
      setLoading(false)
      initialLoading && setInitialLoading(false)
    }
  }

  useEffect(() => {
    if (propsDeviceId && !noInitialFetch) {
      void handleFetch(propsDeviceId)
    }
  }, [propsDeviceId])

  return {
    device,
    handleFetch,
    loading,
    errorText,
    initialLoading,
  }
}
