import React, {FC, useEffect, useMemo, useRef, useState} from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../../../../../app/hooks/useModal'
import { CommonTabMessages } from '../../../../../../../../../config/intl/common-messages/common-tab-messages/CommonTabMessages'
import { FilterOpenButton } from '../../../../../../../../../layouts/custom-buttons/filter-open-button/FilterOpenButton'
import {
  useFilters,
  UseFiltersTestIdsType,
} from '../../../../../../../../../layouts/filters/hook/useFilters'
import { MODAL_TYPES } from '../../../../../../../../../layouts/modals/ModalsProvider'
import { CallTable } from '../../../../../../../../../layouts/tables/call-table/CallTable'
import { DongleListResponse } from '../../../../../../../../../sdk/datagates/types/dongle/_crud/list'
import {
  CallTypeTabs,
  useCalls,
} from '../../../../../../../../../sdk/hooks/use-calls/useCalls'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../../../../../../../../shared/lib/hooks/useLessThanDeviceScreen'
import { Card } from '../../../../../../../../../shared/ui-kit-2/data-display/card/Card'
import { Button } from '../../../../../../../../../shared/ui-kit-2/inputs/button/Button'
import { SegmentedControl } from '../../../../../../../../../shared/ui-kit-2/inputs/segmented-control/SegmentedControl'
import { LC } from '../../../../../../../../../tests/e2e/locators'
import styles from './styles.module.scss'
import {useSip} from "../../../../../../../../../shared/lib/hooks/useSip";
import {useSiteVersion} from "../../../../../../../../../app/hooks/useSiteVersion";
import {Counter} from "../../../../../../../../../shared/ui-kit-2/data-display/counter/Counter";

interface CallListProps {
  activeDevices: DongleListResponse['dongles']
  activeDevicesLoading: boolean
}

const useFiltersTestIds: UseFiltersTestIdsType = {
  dateFromTestId: LC.CALLS.FILTER.DATEPICKER_FROM,
  dateToTestId: LC.CALLS.FILTER.DATEPICKER_TO,
  devicesMultiSelectTestId: LC.CALLS.FILTER.DEVICES_MULTISELECT,
  clearAllButtonTestId: LC.CALLS.FILTER.SUBMIT_BTN,
  submitButtonTestId: LC.CALLS.FILTER.SUBMIT_BTN,
}

const CallsListMessages = defineMessages({
  title: {
    id: 'CallsListMessages.title',
    defaultMessage: 'Calls',
  },
  call: {
    id: 'CallsListMessages.call',
    defaultMessage: 'Call',
  },
})

export const CallsList: FC<CallListProps> = (props) => {
  const { activeDevices, activeDevicesLoading } = props
  const intl = useIntl()
  const isMobile = useLessThanDeviceScreen(ScreenDevices.MOBILE_LK)
  const { siteVersion } = useSiteVersion();

  const [callTypeTab, setCallTypeTab] = useState<string>(CallTypeTabs.ALL)
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(50)

  const audioRef = useRef(null);

  const {
    makeCall,
    answerIncomingCall,
    endCall,
    ua,
    hasOutgoingCall,
    hasIncomingCall,
    callHasAcceptedByOtherSide,
    loading: sipLoading,
    setAudioRef,
  } = useSip();

  useEffect(() => {
    setAudioRef(audioRef.current); // Set audio ref for useSip
  }, [setAudioRef]);

  const { filters, openFilters } = useFilters({
    filterFields: ['dates', 'devices', 'phone-numbers'],
    devices: activeDevices,
    testIds: useFiltersTestIds,
    typeTab: callTypeTab,
    setTypeTab: setCallTypeTab,
  })

  const { calls, totalCount, handleDeleteCall, loading } =
    useCalls({
      page,
      limit,
      callTypeTab,
      filters,
    })

  const { handleOpenModal } = useModal()
  const sliceCalls = calls.slice(0, 3000)

  /*

  useEffect(() => {
    if (hasIncomingCall) {
      handleOpenModal({
        type: MODAL_TYPES.CALL,
        props: {
          sipUser: 'sip_user',
          from: {
            name: 'from_name',
            phoneNumber: 'from_phoneNumber',
            imeiManufacture: 'from_imeiManufacture',
          },
          to: {
            name: 'to_name',
            phoneNumber: 'to_phoneNumber',
          },
          sip: {
            makeCall,
            answerIncomingCall,
            endCall,
            ua,
            hasOutgoingCall,
            hasIncomingCall,
            callHasAcceptedByOtherSide,
          }
        }
      })
    }
  }, [hasIncomingCall]);

  */

  const openCreateCallModal = () => {
    handleOpenModal({
      type: MODAL_TYPES.CREATE_CALL,
      props: {
        makeCall,
        answerIncomingCall,
        endCall,
        ua,
        hasOutgoingCall,
        hasIncomingCall,
        callHasAcceptedByOtherSide,
      }
    })
  }

  const callTypeSegmentedGroup = useMemo(
    () => [
      {
        value: CallTypeTabs.ALL,
        comparedValue: callTypeTab,
        label: intl.formatMessage(CommonTabMessages.filterAll),
        onClick: () => setCallTypeTab(CallTypeTabs.ALL),
        testId: LC.CALLS.TYPE.ALL,
      },
      {
        value: CallTypeTabs.INCOMING,
        comparedValue: callTypeTab,
        label: intl.formatMessage(CommonTabMessages.filterIncoming),
        onClick: () => setCallTypeTab(CallTypeTabs.INCOMING),
        testId: LC.CALLS.TYPE.INCOMING,
      },
      {
        value: CallTypeTabs.OUTGOING,
        comparedValue: callTypeTab,
        label: intl.formatMessage(CommonTabMessages.filterOutgoing),
        onClick: () => setCallTypeTab(CallTypeTabs.OUTGOING),
        testId: LC.CALLS.TYPE.OUTGOING,
      },
      // {
      //   value: CallTypeTabs.DELAYED,
      //   comparedValue: callTypeTab,
      //   label: intl.formatMessage(CommonTabMessages.filterDelayed),
      //   onClick: () => setCallTypeTab(CallTypeTabs.DELAYED),
      //   testId: LC.CALLS.TYPE.DELAYED
      // },
    ],
    [callTypeTab],
  )

  useEffect(() => {
    setPage(1)
  }, [callTypeTab, filters])

  const hasFilters = !!Object.keys(filters).length

  return (
    <Card additionalClassNames={[styles.Card]}>
      <div className={styles.Header}>
        <div className={styles.Title}>
          <span>{intl.formatMessage(CallsListMessages.title)}</span>
          <Counter
            count={totalCount}
            variant='filled'
          />
        </div>
        <div className={styles.HeaderActions}>
          {!isMobile && (
            <SegmentedControl
              name={'call-types'}
              group={callTypeSegmentedGroup}
            />
          )}
          <FilterOpenButton
            hasFilters={hasFilters}
            onClick={openFilters}
            loading={activeDevicesLoading}
          />
          {siteVersion !== 'ru' && (
            <Button
              text={intl.formatMessage(CallsListMessages.call)}
              size={'small'}
              icon={'call'}
              additionalClassNames={[styles.NewCallBtn]}
              onClick={openCreateCallModal}
              loading={sipLoading}
              disabled={sipLoading}
            />
          )}
        </div>
      </div>

      <CallTable
        calls={sliceCalls}
        totalCount={totalCount}
        page={page}
        setPage={setPage}
        setLimit={setLimit}
        pageLimit={limit}
        loading={activeDevicesLoading || loading}
        handleDeleteCall={handleDeleteCall}
        activeDevicesTotalCount={activeDevices.length}
      />

      <audio ref={audioRef} id="remoteAudio" autoPlay playsInline></audio>
    </Card>
  )
}
