import React, { Dispatch, FC, SetStateAction } from 'react'
import { useIntl } from 'react-intl'
import { CommonTableMessages } from '../../../config/intl/common-messages/common-table-messages/CommonTableMessages'
import { CallListResponse } from '../../../sdk/datagates/types/call/_crud/list'
import {
  Table,
  TableColType,
} from '../../../shared/ui-kit-2/data-display/table/Table'
import { LC } from '../../../tests/e2e/locators'
import { NoDevicesForCalls } from '../../status-layouts/no-devices-for-calls/NoDevicesForCalls'
import { CallTableRows } from './components/call-table-rows/CallTableRows'
import { ReactComponent as ArrowRightIcon } from '../../../assets/icons/arrow-right.svg'

interface CallTableProps {
  calls: CallListResponse['calls']
  totalCount: number
  page: number
  setPage: Dispatch<SetStateAction<number>>
  setLimit: Dispatch<SetStateAction<number>>
  pageLimit: number
  loading: boolean
  handleDeleteCall: (dongleId: number, callId: number) => Promise<void>
  activeDevicesTotalCount: number
}

// TODO: Rename to CallsTable
export const CallTable: FC<CallTableProps> = (props) => {
  const {
    calls,
    page,
    setPage,
    setLimit,
    pageLimit,
    totalCount,
    loading,
    handleDeleteCall,
    activeDevicesTotalCount,
  } = props

  const intl = useIntl()

  const handlePageChange = (page: number, limit: number) => {
    setPage(page)
    setLimit(limit)
  }

  const cols: TableColType[] = [
    { title: intl.formatMessage(CommonTableMessages.columnType) },
    { title: intl.formatMessage(CommonTableMessages.columnTime), position: 'center' },
    { title: intl.formatMessage(CommonTableMessages.columnStatus) },
    { title: intl.formatMessage(CommonTableMessages.columnSender) },
    { title: <ArrowRightIcon />, position: 'center' },
    { title: intl.formatMessage(CommonTableMessages.columnReceiver) },
    { title: intl.formatMessage(CommonTableMessages.columnDuration) },
    {
      title: intl.formatMessage(CommonTableMessages.columnActions),
      position: 'right',
    },
  ]

  const rowGroups = CallTableRows(calls, handleDeleteCall)

  return (
    <Table
      cols={cols}
      rowGroups={rowGroups}
      currentPage={page}
      totalCount={totalCount}
      onPageChange={handlePageChange}
      itemsPerPage={pageLimit}
      loading={loading}
      columnWidths={['50px', 0.5, '120px', 1, '58px', 1, 1, 1]}
      noDataComponent={activeDevicesTotalCount ? null : <NoDevicesForCalls />}
      testId={LC.CALLS.TABLE._}
    />
  )
}
