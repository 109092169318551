
import { Card } from '../../../../../../shared/ui-kit-2/data-display/card/Card'
import styles from './styles.module.scss'
import {useDevices} from "../../../../../../sdk/hooks/use-devices/useDevices";
import {Switcher} from "../../../../../../shared/ui-kit-2/inputs/switcher/Switcher";
import {UserAutoRenewal} from "../../../../../../store/reducers/user/types";
import React from "react";
import clsx from "clsx";
import {LoadingContent} from "../../../../../../layouts/loading-content/LoadingContent";

export const CallsSettingsPage = () => {
  const { devices, loading } = useDevices({
    page: 0,
    limit: 0,
    takeAll: true,
    isActive: true,
  });

  // TODO: Refactor and add to global components folder
  const GridHelper = () => (
    <>
      {devices.length < 5 ? [...new Array(5 - devices.length)].map(() => <div></div>) : undefined}
    </>
  );

  const content = (
    <div className={styles.CallsSettingsPage}>
      {devices.map(({ name, phoneNumber }) => (
        <Card additionalClassNames={[styles.Card]}>
          <div className={styles.Name}>
            {name}
          </div>
          <div className={styles.PhoneNumber}>
            {phoneNumber}
          </div>
          <div className={styles.Selector}>
            <div className={clsx(styles.Selector__Card, styles.Selector__Card_active)}>
              Voice call
            </div>
            <div className={styles.Selector__Card}>
              Robotic call
            </div>
          </div>
          <div className={styles.MuteMode}>
            <div className={styles.MuteMode__Col}>
              <div className={styles.MuteMode__Title}>
                Mute mode
              </div>
              <div className={styles.MuteMode__Subtitle}>
                For incoming signals
              </div>
            </div>
            <div className={styles.MuteMode__Col}>
              <Switcher
                checked={false}
                disabled={true}
              />
            </div>
          </div>
        </Card>
      ))}
      <GridHelper />
    </div>
  );

  return (
    <LoadingContent loading={loading} content={content} />
  )
}