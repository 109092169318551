import { defineMessages } from 'react-intl'

export const PrivacyPolicyMessages = defineMessages({
  title: {
    id: 'PrivacyPolicyMessages.title',
    defaultMessage: 'PRIVACY POLICY',
  },
  introduction: {
    id: 'PrivacyPolicyMessages.introduction',
    defaultMessage: 'Introduction',
  },
  description: {
    id: 'PrivacyPolicyMessages.description',
    defaultMessage:
      'This privacy policy applies between you, the user of this Website, and {companyNameBringo}, as the owner and operator of this website {linkTeleleo}, which takes your privacy very seriously. This privacy policy applies to our use of any and all Data collected by us or provided by you in connection with your use of the Website.',
  },
  companyRegistrationDetails: {
    id: 'PrivacyPolicyMessages.сompanyRegistrationDetails',
    defaultMessage:
      '{companyNameBringo} is a company registered in Spain with CIF B72427412, with its registered and actual address at 16-18 Marina Street, 27th floor, Barcelona city, postcode 08005.',
  },
  emailContactDetails: {
    id: 'PrivacyPolicyMessages.emailContactDetails',
    defaultMessage:
      '{companyNameBringo} can be contacted by email at {companyEmail}',
  },
  termsReference: {
    id: 'PrivacyPolicyMessages.termsReference',
    defaultMessage:
      'Please note that this privacy policy should be read in conjunction with our Terms and Conditions, which can be found here: {linkTerms}, as well as on the website itself {linkTeleleo}',
  },
  definitionsAndInterpretationTitle: {
    id: 'PrivacyPolicyMessages.definitionsAndInterpretationTitle',
    defaultMessage: 'Definitions and interpretation',
  },
  privacyPolicyDefinitionsIntro: {
    id: 'PrivacyPolicyMessages.privacyPolicyDefinitionsIntro',
    defaultMessage:
      '1. The following definitions are used in this privacy policy:',
  },
  dataTitle: {
    id: 'PrivacyPolicyMessages.dataTitle',
    defaultMessage: 'Data',
  },
  dataContent: {
    id: 'PrivacyPolicyMessages.dataContent',
    defaultMessage:
      'Data means the information you provide to Bringo Telecom SL through the website. Where appropriate, this definition includes definitions provided in data protection laws',
  },
  cookiesTitle: {
    id: 'PrivacyPolicyMessages.cookiesTitle',
    defaultMessage: 'Сookies',
  },
  cookiesContent: {
    id: 'PrivacyPolicyMessages.cookiesContent',
    defaultMessage:
      'This is a small text file that is placed on your computer by this website when you visit certain areas of the website and/or use certain features of the website. Details of the cookies used by this website are set out in the following section. (Cookies)',
  },
  dataProtectionTitle: {
    id: 'PrivacyPolicyMessages.dataProtectionTitle',
    defaultMessage: 'Data protection laws',
  },
  dataProtectionContent: {
    id: 'PrivacyPolicyMessages.dataProtectionContent',
    defaultMessage:
      'This is a reference to all applicable laws related to the processing of personal data, including but not limited to the General Data Protection Regulation (GDPR) and national laws, rules and regulations governing the processing and protection of personal data',
  },
  gdprTitle: {
    id: 'PrivacyPolicyMessages.gdprTitle',
    defaultMessage: 'RGPD',
  },
  gdprContent: {
    id: 'PrivacyPolicyMessages.gdprContent',
    defaultMessage:
      'EGeneral Data Protection Regulation in Spain and the European Union. Refers to the GDPR (General Data Protection Regulation), which is a European Union law that regulates the protection of personal data in Spain and other EU countries',
  },
  bringoContactsTitle: {
    id: 'PrivacyPolicyMessages.bringoContactsTitle',
    defaultMessage:
      'Bringo Telecom SL Ways of labelling the company in this privacy policy',
  },
  bringoContactsContent: {
    id: 'PrivacyPolicyMessages.bringoContactsContent',
    defaultMessage:
      '{companyNameBringo} is a company registered in Spain, with CIF B72427412, with its registered and effective address at Via (la calle) Marina 16-18, Planta 27, Barcelona with CP 08005.\n' +
      'You can contact Bringo Telecom SL by email at {companyEmail}',
  },
  cookiesUkTitle: {
    id: 'PrivacyPolicyMessages.cookiesUkTitle',
    defaultMessage: 'Spanish and European Union Cookie Law',
  },
  cookiesUkContent: {
    id: 'PrivacyPolicyMessages.cookiesUkContent',
    defaultMessage:
      'The Privacy and Electronic Communications (EU Directive) Regulations 2003, as amended by the Privacy and Electronic Communications (EU Directive) (Amendment) Regulations 2011 and the Privacy and Electronic Communications (EU Directive) (Amendment) Regulations 2018. Is a reference to legislation and developments relating to data protection and electronic communications in the European Union',
  },
  thirdPartyAccessDefinitionTitle: {
    id: 'PrivacyPolicyMessages.thirdPartyAccessDefinitionTitle',
    defaultMessage: 'User or you',
  },
  thirdPartyAccessDefinitionContent: {
    id: 'PrivacyPolicyMessages.thirdPartyAccessDefinitionContent',
    defaultMessage:
      'Any third party who accesses the Website and is not (i) an employee of {companyNameBringo} and acting within the scope of his or her employment or (ii) a hired consultant or service provider providing services to {companyNameBringo} and accessing the Website in connection with the provision of such services',
  },
  websiteDefinitionTitle: {
    id: 'PrivacyPolicyMessages.websiteDefinitionTitle',
    defaultMessage: 'Website',
  },
  websiteDefinitionContent: {
    id: 'PrivacyPolicyMessages.websiteDefinitionContent',
    defaultMessage:
      'The website you are currently using, {linkTeleleo}, and any subdomain of that website, unless expressly excluded by its own terms and conditions',
  },
  interpretationClauseTitle: {
    id: 'PrivacyPolicyMessages.interpretationClauseTitle',
    defaultMessage:
      '2. In this Privacy Policy, unless a different interpretation is required by the context:',
  },
  interpretationClauseAPart: {
    id: 'PrivacyPolicyMessages.interpretationClauseAPart',
    defaultMessage: 'The singular includes the plural and vice versa;',
  },
  interpretationClauseBPart: {
    id: 'PrivacyPolicyMessages.interpretationClauseBPart',
    defaultMessage:
      'References to subparagraphs, paragraphs, annexes or supplements refer to subparagraphs, paragraphs, annexes or supplements of this Privacy Policy;',
  },
  interpretationClauseCPart: {
    id: 'PrivacyPolicyMessages.interpretationClauseCPart',
    defaultMessage:
      'Reference to an individual includes companies, firms, public bodies, trusts and associations;',
  },
  interpretationClauseDPart: {
    id: 'PrivacyPolicyMessages.interpretationClauseDPart',
    defaultMessage:
      "\"'including' shall be construed as 'including, without limitation'\";",
  },
  interpretationClauseEPart: {
    id: 'PrivacyPolicyMessages.interpretationClauseEPart',
    defaultMessage:
      'A reference to a legal provision includes any modification or amendment thereof.',
  },
  scopeOfPrivacyPolicyTitle: {
    id: 'PrivacyPolicyMessages.scopeOfPrivacyPolicyTitle',
    defaultMessage: 'Effect of this privacy policy',
  },
  scopeOfPrivacyPolicyContent: {
    id: 'PrivacyPolicyMessages.scopeOfPrivacyPolicyContent',
    defaultMessage:
      '3. This privacy policy applies only to the actions of {companyNameBringo} and Users with respect to the {linkTeleleo} website. It does not apply to any website accessible from said website, including but not limited to links we may provide to social media websites.',
  },
  dataCollectedTitle: {
    id: 'PrivacyPolicyMessages.dataCollectedTitle',
    defaultMessage: 'Data collected',
  },
  dataCollectedDescription: {
    id: 'PrivacyPolicyMessages.dataCollectedDescription',
    defaultMessage:
      '4. Personal data or personal information means any information about a natural person by which that person can be identified. This does not include data where identification has been removed (anonymised data). We may collect, use, store and transfer different types of your personal data, which we have grouped as follows: ',
  },
  identityData: {
    id: 'PrivacyPolicyMessages.identityData',
    defaultMessage:
      'Identification related data includes first name, last name, photograph, job title.',
  },
  contactData: {
    id: 'PrivacyPolicyMessages.contactData',
    defaultMessage: 'Contact data include email address, telephone number.',
  },
  technicalData: {
    id: 'PrivacyPolicyMessages.technicalData',
    defaultMessage:
      'Technical data includes your IP address(IP), user ID, metadata, browser type and version, browser settings and time zone, browser plugin types and versions, operating system and platform, and other technologies on the devices you use to access this website.',
  },
  profileData: {
    id: 'PrivacyPolicyMessages.profileData',
    defaultMessage: 'Profile Data includes your email address and password.',
  },
  usageData: {
    id: 'PrivacyPolicyMessages.usageData',
    defaultMessage:
      'Usage Data includes information about how you use our website, products and services.',
  },
  marketingAndCommunicationData: {
    id: 'PrivacyPolicyMessages.marketingAndCommunicationData',
    defaultMessage:
      'Marketing and Communications Data includes your preferences for receiving marketing information from us and from third parties and your communication preferences.',
  },
  aggregatedDataProcessing: {
    id: 'PrivacyPolicyMessages.aggregatedDataProcessing',
    defaultMessage:
      'We also collect, use and share aggregated data, such as statistical or demographic data, for any purpose. Aggregated data may be derived from your personal data, but it is not considered personal data under the law because it does not directly or indirectly reveal your identity. For example, we may aggregate your usage data to calculate the percentage of users accessing a particular website feature. However, if we combine or link the aggregated data with your personal data in a way that identifies you directly or indirectly, we process the combined data as personal data to be used in accordance with this Privacy Policy.',
  },
  excludedPersonalDataCategories: {
    id: 'PrivacyPolicyMessages.excludedPersonalDataCategories',
    defaultMessage:
      'We do not collect any special categories of your personal data (this includes information about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health and genetic and biometric data).',
  },
  howWeCollectDataTitle: {
    id: 'PrivacyPolicyMessages.howWeCollectDataTitle',
    defaultMessage: 'How we collect data',
  },
  specificCollectionMethodsTitle: {
    id: 'PrivacyPolicyMessages.specificCollectionMethodsTitle',
    defaultMessage: '5. We collect data in the following ways:',
  },
  specificCollectionMethodsA: {
    id: 'PrivacyPolicyMessages.specificCollectionMethodsA',
    defaultMessage: 'You provide us with data;',
  },
  specificCollectionMethodsB: {
    id: 'PrivacyPolicyMessages.specificCollectionMethodsB',
    defaultMessage: 'Data is obtained from other sources;',
  },
  specificCollectionMethodsC: {
    id: 'PrivacyPolicyMessages.specificCollectionMethodsC',
    defaultMessage: 'Data is collected automatically.',
  },
  userProvidedDataTitle: {
    id: 'PrivacyPolicyMessages.userProvidedDataTitle',
    defaultMessage: 'Data you provide to us',
  },
  bringoDataCollectionMethodsTitle: {
    id: 'PrivacyPolicyMessages.bringoDataCollectionMethodsTitle',
    defaultMessage:
      '6. {companyNameBringo} will collect your data in various ways, such as:',
  },
  bringoDataCollectionMethodsA: {
    id: 'PrivacyPolicyMessages.bringoDataCollectionMethodsA',
    defaultMessage:
      'when you contact us via the website, telephone, post, email or any other means;',
  },
  bringoDataCollectionMethodsB: {
    id: 'PrivacyPolicyMessages.bringoDataCollectionMethodsB',
    defaultMessage:
      'when you register with us and create an account to receive our products/services;',
  },
  bringoDataCollectionMethodsC: {
    id: 'PrivacyPolicyMessages.bringoDataCollectionMethodsC',
    defaultMessage:
      'when you complete surveys that we use for research purposes (you are under no obligation to respond);',
  },
  bringoDataCollectionMethodsD: {
    id: 'PrivacyPolicyMessages.bringoDataCollectionMethodsD',
    defaultMessage: 'when you choose to receive our marketing communications;',
  },
  collectionPolicyCompliance: {
    id: 'PrivacyPolicyMessages.collectionPolicyCompliance',
    defaultMessage: 'in each case, in accordance with this Privacy Policy.',
  },
  dataFromPublicSourcesTitle: {
    id: 'PrivacyPolicyMessages.dataFromPublicSourcesTitle',
    defaultMessage:
      'Data is obtained from publicly available third party sources',
  },
  dataFromCompanies: {
    id: 'PrivacyPolicyMessages.dataFromCompanies',
    defaultMessage:
      '7. We do not obtain data from other sources not listed in this policy. For legal entities (companies), it is possible to obtain information from publicly available information sources such as the public register of companies.',
  },
  automaticallyCollectedDataTitle: {
    id: 'PrivacyPolicyMessages.automaticallyCollectedDataTitle',
    defaultMessage: 'Data that is collected automatically',
  },
  dataCollectedUponWebsiteAccess: {
    id: 'PrivacyPolicyMessages.dataCollectedUponWebsiteAccess',
    defaultMessage:
      '8. As you access the website, we will collect your data automatically, for example:',
  },
  dataCollectedUponWebsiteAccessA: {
    id: 'PrivacyPolicyMessages.dataCollectedUponWebsiteAccessA',
    defaultMessage:
      'we automatically collect certain information about your visit to the website. This information helps us to improve the content and navigation on the website and includes your IP address, the date, time and frequency of access to the website and your activity and interaction with the content.',
  },
  dataCollectedUponWebsiteAccessB: {
    id: 'PrivacyPolicyMessages.dataCollectedUponWebsiteAccessB',
    defaultMessage:
      "we will automatically collect your data using cookies in accordance with your browser's cookie settings. For more information about cookies and how we use them on the website, please refer to the section below headed 'Cookies'.",
  },

  ourUseOfDataTitle: {
    id: 'PrivacyPolicyMessages.ourUseOfDataTitle',
    defaultMessage: 'Our use of data',
  },
  dataUsageIntro: {
    id: 'PrivacyPolicyMessages.dataUsageIntro',
    defaultMessage:
      '9. Any or all of the above data may be requested by us from time to time to provide you with the best possible service and experience when using our website. In particular, data may be used by us for the following purposes:',
  },
  dataUsageIntroA: {
    id: 'PrivacyPolicyMessages.dataUsageIntroA',
    defaultMessage:
      'to register you as a user we use Profile, Technical data types;',
  },
  dataUsageIntroB: {
    id: 'PrivacyPolicyMessages.dataUsageIntroB',
    defaultMessage:
      'to improve our website, products/services, marketing we use Usage, Technical, Marketing and Communications data types;',
  },
  dataUsageIntroC: {
    id: 'PrivacyPolicyMessages.dataUsageIntroC',
    defaultMessage:
      'to notify you of changes to our terms and conditions or privacy policy and to ask you to leave feedback or take a survey, we use Identity, Contact, Marketing and Communications data types;',
  },
  dataUsageIntroD: {
    id: 'PrivacyPolicyMessages.dataUsageIntroD',
    defaultMessage:
      'to provide you with relevant content and advertising from the website and to measure or understand the effectiveness of the advertising we provide to you, we use the data types usage, technical data, marketing and communications, profile, identification, contact;',
  },
  dataUsageIntroE: {
    id: 'PrivacyPolicyMessages.dataUsageIntroE',
    defaultMessage:
      'we use identification, contact and technical data to manage this website (including troubleshooting, data analysis, testing, system maintenance, support, reporting and data hosting).',
  },
  dataUsageCompliance: {
    id: 'PrivacyPolicyMessages.dataUsageCompliance',
    defaultMessage: 'In each case, in accordance with this Privacy Policy.',
  },
  legitimateInterests: {
    id: 'PrivacyPolicyMessages.legitimateInterests',
    defaultMessage:
      "10. We may use your data for the above purposes if we believe it is necessary for our legitimate interests. If you do not agree, you have the right to object to this in certain circumstances (see 'Your Rights' below).",
  },
  emailMarketingConsent: {
    id: 'PrivacyPolicyMessages.emailMarketingConsent',
    defaultMessage:
      '11. In order to send you marketing offers by email, we will need your consent, either through opt-in or soft-opt-in.',
  },
  emailMarketingConsentA: {
    id: 'PrivacyPolicyMessages.emailMarketingConsentA',
    defaultMessage:
      "If you are not happy with our marketing approach, you have the right to withdraw your consent at any time. Read about how to withdraw your consent in the 'Your Rights' section below.",
  },
  emailMarketingConsentB: {
    id: 'PrivacyPolicyMessages.emailMarketingConsentB',
    defaultMessage:
      'When you register with us and create an account to receive our services, the legal basis for such processing is the execution of a contract between you and us and/or taking steps to enter into such a contract at your request.',
  },
  emailMarketingConsentC: {
    id: 'PrivacyPolicyMessages.emailMarketingConsentC',
    defaultMessage:
      "If you are not happy with our marketing approach, you have the right to withdraw your consent at any time. Read about how to withdraw your consent in the 'Your Rights' section below.",
  },
  registrationLegalBasis: {
    id: 'PrivacyPolicyMessages.registrationLegalBasis',
    defaultMessage:
      '12. When you register with us and create an account to receive our services, the legal basis for such processing is the execution of a contract between you and us and/or taking steps to enter into such a contract at your request.',
  },
  ensuringDataSecurityTitle: {
    id: 'PrivacyPolicyMessages.ensuringDataSecurityTitle',
    defaultMessage: 'Ensuring data security',
  },
  ensuringDataSecurityIntro: {
    id: 'PrivacyPolicyMessages.ensuringDataSecurityIntro',
    defaultMessage:
      '13. We use technical and organisational measures to protect your data, for example:',
  },
  ensuringDataSecurityA: {
    id: 'PrivacyPolicyMessages.ensuringDataSecurityA',
    defaultMessage:
      'Access to your account is controlled by a password and username unique to you.',
  },
  ensuringDataSecurityB: {
    id: 'PrivacyPolicyMessages.ensuringDataSecurityB',
    defaultMessage: 'We store your data on secure servers.',
  },
  technicalAndOrganisationalMeasures: {
    id: 'PrivacyPolicyMessages.technicalAndOrganisationalMeasures',
    defaultMessage:
      '14. Technical and organisational measures include measures to respond to any suspected data breach. If you suspect misuse, loss or unauthorised access to your Data(s), please notify us immediately by contacting us at this email address: info@bringo.eu.',
  },
  dataRetentionTitle: {
    id: 'PrivacyPolicyMessages.dataRetentionTitle',
    defaultMessage: 'Data Retention',
  },
  dataRetentionPolicy: {
    id: 'PrivacyPolicyMessages.dataRetentionPolicy',
    defaultMessage:
      '15. Unless a longer retention period is required or permitted by law, we retain your data on our systems only for the period necessary to fulfil the purposes described in this privacy policy or until you request that the data be deleted.',
  },
  dataRetentionBackup: {
    id: 'PrivacyPolicyMessages.dataRetentionBackup',
    defaultMessage:
      '16. Even if we delete your data, it may be retained on backup or archival media for legal, tax or regulatory purposes.',
  },
  yourRightsTitle: {
    id: 'PrivacyPolicyMessages.yourRightsTitle',
    defaultMessage: 'Your rights',
  },
  dataRightsOverview: {
    id: 'PrivacyPolicyMessages.dataRightsOverview',
    defaultMessage: 'You have the following rights in relation to your data:',
  },
  dataRightsOverviewA: {
    id: 'PrivacyPolicyMessages.dataRightsOverviewA',
    defaultMessage:
      'Right of Access: the right to request at any time (i) copies of the information we hold about you, or (ii) to amend, update or delete such information. If we give you access to the information we hold about you, we will not charge you for this unless your request is "manifestly unreasonable or excessive". If we are legally entitled to do so, we may refuse your request. If we refuse your request, we will tell you the reason why.',
  },
  dataRightsOverviewB: {
    id: 'PrivacyPolicyMessages.dataRightsOverviewB',
    defaultMessage:
      'Right to rectification: the right to have your data corrected if it is inaccurate or incomplete.',
  },
  dataRightsOverviewC: {
    id: 'PrivacyPolicyMessages.dataRightsOverviewC',
    defaultMessage:
      'Right to erasure: the right to request that we delete or destroy your data from our systems.',
  },
  dataRightsOverviewD: {
    id: 'PrivacyPolicyMessages.dataRightsOverviewD',
    defaultMessage:
      'The right to restrict the use of your data: the right to "prohibit" us from using your data or to restrict how we use it.',
  },
  dataRightsOverviewE: {
    id: 'PrivacyPolicyMessages.dataRightsOverviewE',
    defaultMessage:
      'The right to data portability: the right to request that we move, copy or transfer your data.',
  },
  dataRightsOverviewF: {
    id: 'PrivacyPolicyMessages.dataRightsOverviewF',
    defaultMessage:
      'Right to object: the right to object to our use of your data, including when we use it for our legitimate interests.',
  },
  contactUs: {
    id: 'PrivacyPolicyMessages.contactUs',
    defaultMessage:
      'To ask questions, exercise any of the above rights or withdraw your consent to the processing of your data (where consent is our lawful basis for processing your data), please contact us at This email address is info@bringo.eu.',
  },
  handleComplaint: {
    id: 'PrivacyPolicyMessages.handleComplaint',
    defaultMessage:
      'If you are not satisfied with the way we handle a complaint you have made about your data, you may contact the relevant data protection authority.',
  },
  dataAccuracy: {
    id: 'PrivacyPolicyMessages.dataAccuracy',
    defaultMessage:
      'It is important that the data we hold about you is accurate and up to date. Please let us know if your data has changed during the period for which we hold it.',
  },

  linksToOtherSitesTitle: {
    id: 'PrivacyPolicyMessages.linksToOtherSitesTitle',
    defaultMessage: 'Links to other sites',
  },
  linksToOtherSitesContent: {
    id: 'PrivacyPolicyMessages.linksToOtherSitesContent',
    defaultMessage:
      '21. This website may from time to time contain links to other websites. We have no control over these sites and are not responsible for their content. This privacy policy does not apply to your use of such sites. We encourage you to read the privacy policy or privacy statement of other sites before using them.',
  },
  changeInOwnershipTitle: {
    id: 'PrivacyPolicyMessages.changeInOwnershipTitle',
    defaultMessage: 'Change in ownership and control of the business',
  },
  changeInOwnershipOverview: {
    id: 'PrivacyPolicyMessages.changeInOwnershipOverview',
    defaultMessage:
      '22. {companyNameBringo} may from time to time expand or reduce its operations, which may involve the sale and/or transfer of control of all or part of Bringo Telecom SL. Data provided by Users, if it relates to any transferred part of our business, will be transferred with that part, and the new owner or new controlling party, subject to the terms of this privacy policy, will be authorised to use that data for the purposes for which it was originally provided to us.',
  },
  changeInOwnershipProspectivePurchaser: {
    id: 'PrivacyPolicyMessages.changeInOwnershipProspectivePurchaser',
    defaultMessage:
      '23. We may also disclose data to a prospective purchaser of our business or part of it.',
  },
  changeInOwnershipPrivacyProtection: {
    id: 'PrivacyPolicyMessages.changeInOwnershipPrivacyProtection',
    defaultMessage:
      '24. In the above cases, we will take steps to ensure that your privacy is protected.',
  },
  cookiesTitleTable: {
    id: 'PrivacyPolicyMessages.cookiesTitle',
    defaultMessage: 'Cookies',
  },
  cookiesOverview: {
    id: 'PrivacyPolicyMessages.cookiesOverview',
    defaultMessage:
      '25. This website may place and access certain cookies on your computer. Bringo Telecom SL uses cookies to improve your experience on the website and to improve the quality of our services. Bringo Telecom SL carefully selects these cookies and takes steps to ensure that your privacy is protected and respected at all times.',
  },
  cookiesLegislation: {
    id: 'PrivacyPolicyMessages.cookiesLegislation',
    defaultMessage:
      '26. All cookies used on this website are used in accordance with current UK and EU cookie legislation.',
  },
  cookiesConsent: {
    id: 'PrivacyPolicyMessages.cookiesConsent',
    defaultMessage:
      '27. Before the website places cookies on your computer, a message bar will appear on your screen asking for your consent to set cookies. By giving your consent to the placing of cookies, you are enabling Bringo Telecom SL to provide you with a better service. If you wish to do so, you can refuse consent to the placing of cookies, but in this case some of the functions of the site may not function fully or as intended.',
  },
  followingCookies: {
    id: 'PrivacyPolicyMessages.followingCookies',
    defaultMessage: '28. The following cookies may be placed on this website:',
  },
  cookieTypeHeadTable: {
    id: 'PrivacyPolicyMessages.cookieTypeHeadTable',
    defaultMessage: 'Cookie type',
  },
  cookiePurposeHeadTable: {
    id: 'PrivacyPolicyMessages.cookiePurposeHeadTable',
    defaultMessage: 'Purpose',
  },
  cookieTypeBodyTable: {
    id: 'PrivacyPolicyMessages.cookieTypeBodyTable',
    defaultMessage: 'Statistical cookies',
  },
  cookiePurposeBodyTable: {
    id: 'PrivacyPolicyMessages.cookiePurposeBodyTable',
    defaultMessage:
      'Statistical cookies help website owners understand how visitors interact with the site by collecting and presenting information in an anonymised form.',
  },
  cookiesBrowserSettings: {
    id: 'PrivacyPolicyMessages.cookiesBrowserSettings',
    defaultMessage:
      '29. You can enable or disable cookies in your Internet browser. Please refer to the help menu of your Internet browser for more information.',
  },
  cookiesDisableImpact: {
    id: 'PrivacyPolicyMessages.cookiesDisableImpact',
    defaultMessage:
      '30. You can disable cookies at any time, however in doing so you may lose any information that will allow you to access the site more quickly and efficiently. You can delete cookies at any time, however in doing so you may lose any information that will allow you to access the site more quickly and efficiently, including in particular your personalisation settings.',
  },
  cookiesBrowserUpdate: {
    id: 'PrivacyPolicyMessages.cookiesBrowserUpdate',
    defaultMessage:
      '31. It is recommended that you ensure that your web browser is up to date and that you refer to the help and guidance provided by the developer of your web browser if you are unsure about your privacy settings.',
  },
  cookiesAdditionalResources: {
    id: 'PrivacyPolicyMessages.cookiesAdditionalResources',
    defaultMessage:
      '32. More information about cookies, including how to disable them,',
  },
  generalTitle: {
    id: 'PrivacyPolicyMessages.generalTitle',
    defaultMessage: 'General',
  },
  generalInfoRightsTransfer: {
    id: 'PrivacyPolicyMessages.generalInfoRightsTransfer',
    defaultMessage:
      '33. You may not transfer any of your rights under this privacy policy to another person. We may transfer our rights under this Privacy Policy if we reasonably believe that your rights will not be affected.',
  },
  generalInfoInvalidProvisions: {
    id: 'PrivacyPolicyMessages.generalInfoInvalidProvisions',
    defaultMessage:
      '34. If any court or competent authority determines that any provision of this Privacy Policy (or part of any provision) is invalid, illegal or unenforceable, such provision or part of the provision, to the extent required, will be deemed deleted and the validity and enforceability of the remaining provisions of this Privacy Policy will not be affected.',
  },
  generalInfoNoDelay: {
    id: 'PrivacyPolicyMessages.generalInfoNoDelay',
    defaultMessage:
      '35. Unless otherwise agreed, no delay, act or omission by a party in exercising any right or remedy shall be deemed a waiver of that or any other right or remedy.',
  },
  generalInfoLawAndJurisdiction: {
    id: 'PrivacyPolicyMessages.generalInfoLawAndJurisdiction',
    defaultMessage:
      '36. This Agreement shall be governed by and construed in accordance with Spanish and EU law. All disputes arising in connection with the Agreement shall be subject to the exclusive jurisdiction of the courts of Spain and the EU.',
  },
  privacyPolicyChangesTitle: {
    id: 'PrivacyPolicyMessages.privacyPolicyChangesTitle',
    defaultMessage: 'Changes to this privacy policy',
  },
  privacyPolicyChanges: {
    id: 'PrivacyPolicyMessages.privacyPolicyChanges',
    defaultMessage:
      '37. {companyNameBringo} reserves the right to change this privacy policy from time to time at its discretion or as required by law. Any changes will be immediately posted on the site and you will be deemed to have accepted the terms of the privacy policy the first time you use the site after the changes have been made. You may contact {companyNameBringo} by email: {companyEmail}.',
  },
  attributionTitle: {
    id: 'PrivacyPolicyMessages.attributionTitle',
    defaultMessage: 'Attribution',
  },
  attribution: {
    id: 'PrivacyPolicyMessages.attribution',
    defaultMessage:
      '38. This privacy policy was created using a document from Rocket Lawyer ({rocketLink}).',
  },
})
