import { defineMessages } from 'react-intl'

export const CommonTableMessages = defineMessages({
  footerInfo: {
    id: 'CommonTableMessages.footerInfo',
    defaultMessage: 'Showing {from} to {to} of {total} entries',
  },
  columnId: {
    id: 'CommonTableMessages.columnId',
    defaultMessage: 'ID',
  },
  columnName: {
    id: 'CommonTableMessages.columnName',
    defaultMessage: 'Name',
  },
  columnDate: {
    id: 'CommonTableMessages.columnDate',
    defaultMessage: 'Date',
  },
  columnNumber: {
    id: 'CommonTableMessages.columnNumber',
    defaultMessage: 'Number',
  },
  columnActions: {
    id: 'CommonTableMessages.columnActions',
    defaultMessage: 'Actions',
  },
  columnTime: {
    id: 'CommonTableMessages.columnTime',
    defaultMessage: 'Time',
  },
  columnDevice: {
    id: 'CommonTableMessages.columnDevice',
    defaultMessage: 'Device Name',
  },
  columnSender: {
    id: 'CommonTableMessages.columnSender',
    defaultMessage: 'Sender',
  },
  columnReceiver: {
    id: 'CommonTableMessages.columnReceiver',
    defaultMessage: 'Receiver',
  },
  columnSmsc: {
    id: 'CommonTableMessages.columnSmsc',
    defaultMessage: 'SMSC',
  },
  columnRedirect: {
    id: 'CommonTableMessages.columnRedirect',
    defaultMessage: 'Redirect',
  },
  columnText: {
    id: 'CommonTableMessages.columnText',
    defaultMessage: 'Text',
  },
  columnSms: {
    id: 'CommonTableMessages.columnSms',
    defaultMessage: 'SMS',
  },
  columnCalls: {
    id: 'CommonTableMessages.columnCalls',
    defaultMessage: 'Calls',
  },
  columnHotspotStatus: {
    id: 'CommonTableMessages.columnHotspotStatus',
    defaultMessage: 'WI-FI status',
  },
  columnStatus: {
    id: 'CommonTableMessages.columnStatus',
    defaultMessage: 'Status',
  },
  columnNetwork: {
    id: 'CommonTableMessages.columnNetwork',
    defaultMessage: 'Network',
  },
  columnType: {
    id: 'CommonTableMessages.columnType',
    defaultMessage: 'Type',
  },
  columnPurpose: {
    id: 'CommonTableMessages.columnPurpose',
    defaultMessage: 'Purpose',
  },
  columnAmount: {
    id: 'CommonTableMessages.columnAmount',
    defaultMessage: 'Amount',
  },
  columnAmountOfNumbers: {
    id: 'CommonTableMessages.columnAmountOfNumbers',
    defaultMessage: 'Recipients',
  },
  columnSendTime: {
    id: 'CommonTableMessages.columnSendTime',
    defaultMessage: 'Send time',
  },
  columnDownloadPdf: {
    id: 'CommonTableMessages.columnDownloadPdf',
    defaultMessage: 'Download PDF',
  },
  columnGroups: {
    id: 'CommonTableMessages.columnGroups',
    defaultMessage: 'Groups',
  },
  columnPhone: {
    id: 'CommonTableMessages.columnPhone',
    defaultMessage: 'Phone',
  },
  columnDuration: {
    id: 'CommonTableMessages.columnDuration',
    defaultMessage: 'Duration',
  },
  columnNotificationName: {
    id: 'CommonTableMessages.columnNotificationName',
    defaultMessage: 'Notification name',
  },
  columnModems: {
    id: 'CommonTableMessages.columnModems',
    defaultMessage: 'Modems',
  },
  columnNumbers: {
    id: 'CommonTableMessages.columnNumbers',
    defaultMessage: 'Numbers',
  },
  columnEmail: {
    id: 'CommonTableMessages.columnEmail',
    defaultMessage: 'Email',
  },
  columnTelegram: {
    id: 'CommonTableMessages.columnTelegram',
    defaultMessage: 'Telegram',
  },
})
