import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router'
import { useSiteVersion } from '../../app/hooks/useSiteVersion'
import { AuthRoutePath } from '../../config/routes/constants/authRoutePath'
import { RoutePath } from '../../config/routes/constants/routePath'
import { AuthRoute } from '../../config/routes/enums/authRoute'
import { Route } from '../../config/routes/enums/route'
import styles from './styles.module.scss'

export const FooterMessages = defineMessages({
  copyright: {
    id: 'FooterMessages.copyright',
    defaultMessage: '© {year} Teleleo',
  },
  legalNotice: {
    id: 'FooterMessages.legalNotice',
    defaultMessage: 'Legal Notice',
  },
  termsAndConditions: {
    id: 'FooterMessages.termsAndConditions',
    defaultMessage: 'Terms & Conditions',
  },
  privacyPolicy: {
    id: 'FooterMessages.privacyPolicy',
    defaultMessage: 'Privacy Policy',
  },
})

const footerNotShownGroup = [
  AuthRoutePath[AuthRoute.SignIn],
  AuthRoutePath[AuthRoute.SignUp],
  AuthRoutePath[AuthRoute.RecoverPassword],
  RoutePath[Route.Home],
]

export const Footer = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { siteVersion } = useSiteVersion()

  if (footerNotShownGroup.includes(pathname)) return null

  const toLegalNotice = () => navigate(RoutePath[Route.LegalNotice])
  const toPrivacyPolicy = () => navigate(RoutePath[Route.PrivacyPolicy])
  const toTermsAndConditions = () => navigate(RoutePath[Route.TermsOfUse])

  return (
    <div className={styles.footer}>
      <div className={styles.footer_content}>
        <div>
          {intl.formatMessage(FooterMessages.copyright, {
            year: new Date(Date.now()).getFullYear(),
          })}
        </div>

        <div className={styles.footer_content_links}>
          {siteVersion === 'en' && (
            <>
              <div
                className={styles.footer_content_links_link}
                onClick={toLegalNotice}
              >
                {intl.formatMessage(FooterMessages.legalNotice)}
              </div>
              <div
                className={styles.footer_content_links_link}
                onClick={toTermsAndConditions}
              >
                {intl.formatMessage(FooterMessages.termsAndConditions)}
              </div>
            </>
          )}
          <div
            className={styles.footer_content_links_link}
            onClick={toPrivacyPolicy}
          >
            {intl.formatMessage(FooterMessages.privacyPolicy)}
          </div>
        </div>
      </div>
    </div>
  )
}
