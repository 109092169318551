import throttle from 'lodash/throttle'
import { defineMessages, useIntl } from 'react-intl'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { SNACKBAR_TYPES } from '../../../layouts/snackbar/SnackbarProvider'
import { documentGetDatagate } from '../../datagates/api/document'

const UseDocumentsMessages = defineMessages({
  downloadError: {
    id: 'BillingHistoryTableCardRowsMessages.downloadError',
    defaultMessage: 'An error occurred while opening file',
  },
})

const throttleIsMs = 500

export const useDocuments = () => {
  const intl = useIntl()
  const { handleOpenSnackbar } = useSnackbar()

  const handleOpenPdf = throttle(async (fileId: number) => {
    try {
      const { data } = await documentGetDatagate(null, [
        {
          name: 'document_id',
          value: fileId.toString(),
        },
      ])

      const link = document.createElement('a')
      link.download = data.name
      link.href = data.downloadUrl
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: intl.formatMessage(UseDocumentsMessages.downloadError),
      })
    }
  }, throttleIsMs)

  return {
    handleOpenPdf,
  }
}
