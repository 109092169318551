import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import messageImage from '../../../assets/images/message.png'
import { Tip } from '../Tip'
import {useSiteVersion} from "../../../app/hooks/useSiteVersion";

const SmsMailingLimitTipMessages = defineMessages({
  text: {
    id: 'SmsMailingLimitTipMessages.text',
    defaultMessage:
      'You can send no more than 300 messages per day. If you need more, please contact us at {email}',
  },
})

const SUPPORT_EMAIL_GLOBAL = 'support@teleleo.com'
const SUPPORT_EMAIL_RU = 'support@telecorn.ru'

export const SmsMailingLimitTip = () => {
  const intl = useIntl()
  const { siteVersion } = useSiteVersion()

  const supportEmail = siteVersion === 'ru' ? SUPPORT_EMAIL_RU : SUPPORT_EMAIL_GLOBAL;

  return (
    <Tip
      imageSource={messageImage}
      text={intl.formatMessage(SmsMailingLimitTipMessages.text, {
        email: <a href={`mailto:${supportEmail}`}>{supportEmail}</a>,
      })}
    />
  )
}
