// @ts-nocheck
import { useState, useEffect, useRef } from 'react';
import { UserAgent, Inviter, Invitation, Registerer, SessionState } from 'sip.js';
import {useModal} from "../../../app/hooks/useModal";
import {sipUserGetDatagate} from "../../../sdk/datagates/api/sip-user";
import {useDispatch} from "react-redux";
import {setSip} from "../../../store/reducers/sip";
import audioPlayer from '../lib/audioPlayer';

export const useSip = () => {
  const [ua, setUa] = useState(null);
  const [hasOutgoingCall, setHasOutgoingCall] = useState<boolean>(false);
  const [hasIncomingCall, setHasIncomingCall] = useState<boolean>(false);
  const [callHasAcceptedByOtherSide, setCallHasAcceptedByOtherSide] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [sipConfig, setSipConfig] = useState(null);
  const [userAgent, setUserAgent] = useState(null);
  const [sipError, setSipError] = useState<boolean>(false);
  const callRef = useRef(null);
  const registererRef = useRef(null);
  const audioRef = useRef(null);
  const dispatch = useDispatch();

  const { handleHideModal } = useModal();

  const initializeUserAgent = async () => {
    const { data } = await sipUserGetDatagate();

    if (data) {
      const {
        sipLogin,
        sipPassword,
        pbxSipUrl
      } = data;

      const config = {
        displayName: sipLogin,
        uri: `sip:${sipLogin}@${pbxSipUrl || 'pbx.telecorn.com'}`,
        password: sipPassword,
        socket: `wss://${pbxSipUrl || 'pbx.telecorn.com'}:7443`
      }

      setSipConfig(config);
      dispatch(setSip({

      }));

      try {
        const userAgent = new UserAgent({
          uri: UserAgent.makeURI(config.uri),
          transportOptions: {
            wsServers: [config.socket],
          },
          authorizationUsername: config.displayName,
          authorizationPassword: config.password,
          displayName: config.displayName,
        });

        if (userAgent) {
          setUserAgent(userAgent);

          userAgent.start()
            .then(() => {
              registererRef.current = new Registerer(userAgent);
              registererRef.current.register()
                .catch((error) => console.error("Failed to register:", error));

              setUa(userAgent);
            })
            .catch((error) => console.error("Failed to start UserAgent:", error));

          userAgent.delegate = {
            onInvite: (invitation) => {
              callRef.current = invitation;
              setHasIncomingCall(true);

              invitation.delegate = {
                onCancel: () => {
                  handleRemoteHangup();
                },
                onTerminated: () => {
                  handleRemoteHangup();
                }
              };
            }
          };
        }
      } catch {
        setSipError(true);
      }
    }
  }

  useEffect(() => {
    initializeUserAgent();

    return () => {
      if (userAgent) {
        userAgent.stop();
        audioPlayer.stop();
      }
      try {
        if (registererRef.current) registererRef.current.unregister();
      } catch {}
    };
  }, []);

  const connectMediaStream = (sessionDescriptionHandler) => {
    const remoteStream = new MediaStream();
    sessionDescriptionHandler.peerConnection.getReceivers().forEach(receiver => {
      if (receiver.track) {
        remoteStream.addTrack(receiver.track);
      }
    });

    if (audioRef.current) {
      audioRef.current.srcObject = remoteStream;
      audioRef.current.play().catch(error => console.error("Failed to play audio:", error));
    }
  };

  const makeCall = ({ targetUriSip, targetUriID }) => {
    if (!ua) return;

    const targetUri = `sip:${targetUriSip}%23${targetUriID}@pbx.telecorn.com`

    setLoading(true);

    if (!audioPlayer.isPlaying('ringback')) {
      audioPlayer.play('ringback');
    }

    const inviter = new Inviter(ua, UserAgent.makeURI(targetUri), {
      sessionDescriptionHandlerOptions: { constraints: { audio: true, video: false } }
    });

    inviter.stateChange.addListener((newState) => {
      if (newState === SessionState.Established) {
        audioPlayer.stop('ringback');
        audioPlayer.play('answered');
        setCallHasAcceptedByOtherSide(true);
        setLoading(false);
      }
      if (newState === SessionState.Terminated) {
        handleRemoteHangup();
      }
    });

    inviter.delegate = {
      onAccept: () => {
      },
      onTerminate: () => {
        handleRemoteHangup();
      }
    };

    inviter.invite()
      .then(() => {
        callRef.current = inviter;
        setHasOutgoingCall(true);
        connectMediaStream(inviter.sessionDescriptionHandler);
        setCallHasAcceptedByOtherSide(true);
        setLoading(false);
      })
      .catch((error) => console.error("Failed to make call:", error));
  };

  const answerIncomingCall = () => {
    if (callRef.current && callRef.current instanceof Invitation) {
      callRef.current.accept({ sessionDescriptionHandlerOptions: { constraints: { audio: true, video: false } } })
        .then(() => {
          setCallHasAcceptedByOtherSide(true);
          setHasIncomingCall(true);
          setLoading(false);
          connectMediaStream(callRef.current.sessionDescriptionHandler);
        })
        .catch((error) => console.error("Failed to answer call:", error));
    }
  };

  const handleSessionEnd = () => {
    if (audioPlayer.isPlaying('ringback')) {
      audioPlayer.stop('ringback');
    }
    if (audioPlayer.isPlaying('ringing')) {
      audioPlayer.stop('ringing');
    }
  }

  const endCall = () => {
    if (!callRef.current) return;

    handleSessionEnd();

    switch (callRef.current.state) {
      case SessionState.Initial:
      case SessionState.Establishing:
        if (callRef.current instanceof Inviter) {
          callRef.current.cancel();
        } else {
          callRef.current.reject();
        }
        break;

      case SessionState.Established:
        callRef.current.bye();
        break;

      case SessionState.Terminating:
        break;
      case SessionState.Terminated:
        break;

      default:
        break;
    }

    resetCallState();
  };

  const handleRemoteHangup = () => {
    if (callRef.current) {
      handleSessionEnd();
      resetCallState();
    }
  };

  const resetCallState = () => {
    setHasOutgoingCall(false);
    setHasIncomingCall(false);
    setCallHasAcceptedByOtherSide(false);
    setLoading(false);

    setTimeout(() => {
      handleHideModal();
    }, 1500)

    callRef.current = null;
  };

  return {
    makeCall,
    answerIncomingCall,
    endCall,
    ua,
    hasOutgoingCall,
    hasIncomingCall,
    callHasAcceptedByOtherSide,
    loading,
    setAudioRef: (ref) => (audioRef.current = ref),
    sipError,
  };
};
