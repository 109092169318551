import clsx from 'clsx'
import { defineMessages, useIntl } from 'react-intl'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../../../../shared/lib/hooks/useLessThanDeviceScreen'
import { ReactComponent as BorderImg } from '../../../assets/border.svg'
import DotsMobileBackground from '../../../assets/dots-mobile.png'
import DotsBackground from '../../../assets/dots.png'
import ModemPng from '../../../assets/modem.png'
import ModemRuPng from '../../../assets/modem-ru.png'
import { HomeSectionIds } from '../../../constants/section-anchors/SectionAnchors'
import styles from './styles.module.scss'
import {useSiteVersion} from "../../../../../app/hooks/useSiteVersion";

type ItemProps = {
  text: string
  direction?: 'left' | 'right'
}

export const Home05Messages = defineMessages({
  title1: {
    id: 'Home05Messages.title1',
    defaultMessage: 'Pocket-Size',
  },
  title2: {
    id: 'Home05Messages.title2',
    defaultMessage: 'Wireless',
  },
  title3: {
    id: 'Home05Messages.title3',
    defaultMessage: 'Hub',
  },
  item1: {
    id: 'Home05Messages.item1',
    defaultMessage:
      'Teleleo Modem is UNLOCKED to all networks so use a sim card of your choice',
  },
  item2: {
    id: 'Home05Messages.item2',
    defaultMessage:
      'Secure and reliable internet connection with A high 4G download speed up to 150 Mbps',
  },
  item3: {
    id: 'Home05Messages.item3',
    defaultMessage: 'Transmission distance of 40 meters',
  },
  item4: {
    id: 'Home05Messages.item4',
    defaultMessage:
      'The USB interface supports plug-and-play and supports up to 8+1 users sharing the network',
  },
})

export const Home05 = () => {
  const isLessThanTablet = useLessThanDeviceScreen(ScreenDevices.LAPTOP)
  const intl = useIntl()
  const { siteVersion } = useSiteVersion()

  const Item = ({ text, direction = 'left' }: ItemProps) => (
    <div className={styles.Item}>
      <div
        className={clsx(
          styles.Line,
          direction === 'right' && styles.Line_right,
        )}
      >
        <BorderImg />
      </div>
      <div className={styles.Text}>{text}</div>
    </div>
  )

  return (
    <div className={styles.Container} id={HomeSectionIds.PRODUCT}>
      <div className={styles.Background}>
        <img
          src={isLessThanTablet ? DotsMobileBackground : DotsBackground}
          alt=""
        />
      </div>
      <div className={styles.Title}>
        {intl.formatMessage(Home05Messages.title1)}{' '}
        <span className={styles.Marked}>
          {intl.formatMessage(Home05Messages.title2)}
        </span>{' '}
        {intl.formatMessage(Home05Messages.title3)}
      </div>
      <div className={styles.Wrapper}>
        <div className={styles.Col}>
          <Item text={intl.formatMessage(Home05Messages.item1)} />
          <Item text={intl.formatMessage(Home05Messages.item2)} />
        </div>
        <div className={styles.Modem}>
          <img src={siteVersion === 'ru' ? ModemRuPng : ModemPng} alt="" />
        </div>
        <div className={clsx(styles.Col, styles.Col_right)}>
          <Item
            text={intl.formatMessage(Home05Messages.item3)}
            direction={'right'}
          />
          <Item
            text={intl.formatMessage(Home05Messages.item4)}
            direction={'right'}
          />
        </div>
      </div>
    </div>
  )
}
