import React, { FC, ReactNode, useEffect, useState } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import {formatClasses} from "../../../lib/utils/format-classes/formatClasses";

export type CloudTabType = {
  id: string
  label: string
  render?: ReactNode
  onClick?: () => void
  testId?: string
  disabled?: boolean
}

type CloudTabsType = Array<CloudTabType>

interface CloudTabsProps {
  items: CloudTabsType
  defaultTabId?: string
  handleChange?: (id: string) => void
  classes?: {
    container?: string[] | string,
    nav?: string[] | string,
  }
}

export const CloudTabs: FC<CloudTabsProps> = ({
  items,
  defaultTabId,
  handleChange = () => null,
  classes,
  ...props
}) => {
  const classesContainer = formatClasses(classes?.container);
  const classesNav = formatClasses(classes?.nav);

  const getTabById = (foundedId: string) =>
    items.find(({ id }) => id === foundedId) || items[0]

  const [selectedTab, setSelectedTab] = useState<CloudTabType>(
    defaultTabId ? getTabById(defaultTabId) : items[0],
  )

  const handleChangeTab = (id: string) => {
    handleChange(id)
    setSelectedTab(getTabById(id))
  }

  useEffect(() => {
    setSelectedTab(getTabById(selectedTab.id))
  }, [items, selectedTab.id])

  return (
    <div {...props} className={clsx(styles.CloudTabsWrapper, ...classesContainer)}>
      <div className={clsx(styles.CloudTabs, ...classesNav)}>
        {items?.map(({ id, label, testId, disabled }) => (
          <button
            key={id}
            className={clsx(
              styles.CloudTab,
              selectedTab.id === id && styles.CloudTab_selected,
            )}
            onClick={() => handleChangeTab(id)}
            data-test-id={testId}
            disabled={disabled}
          >
            {label}
          </button>
        ))}
      </div>
      {selectedTab?.render}
    </div>
  )
}
