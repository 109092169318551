import { FAQPage } from '../../../../../pages/_common/faq/FAQPage'
import { RoutePath } from '../../../constants/routePath'
import { Route } from '../../../enums/route'
import { RouteDescriptor } from '../../../types/routeDescriptor'

export const faq: RouteDescriptor<Route.FAQ> = {
  route: Route.FAQ,
  type: 'page',
  title: 'Teleleo',
  path: RoutePath[Route.FAQ],
  render: FAQPage,
  accessLevel: 'all',
  noAccessRedirectPath: '/',
}
