import { SmsPage } from '../../../../../../../pages/lk/subpages/sms/SmsPage'
import { AuthRoutePath } from '../../../../../constants/authRoutePath'
import { RoutePath } from '../../../../../constants/routePath'
import { AuthRoute } from '../../../../../enums/authRoute'
import { Route } from '../../../../../enums/route'
import { RouteDescriptor } from '../../../../../types/routeDescriptor'

export const sms: RouteDescriptor<Route.Sms> = {
  route: Route.Sms,
  type: 'subpage',
  title: 'Teleleo',
  path: RoutePath[Route.Sms],
  render: SmsPage,
  accessLevel: 'user',
  noAccessRedirectPath: AuthRoutePath[AuthRoute.SignIn],
}
