import styles from "./styles.module.scss";
import { ReactComponent as CloseIcon } from '../../../../assets/icons/submodal-close.svg'
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";
import {Button, BUTTON_ICON_LIST_TYPE, VariantType} from "../../inputs/button/Button";
import Dialog from "@mui/material/Dialog";
import {DialogContent} from "@mui/material";


interface IDialogProps {
  isOpen: boolean,
  props: {
    title: string,
    subtitle?: string,
    cancel: {
      variant?: VariantType,
      icon?: BUTTON_ICON_LIST_TYPE | undefined,
      text: string,
      onClick: () => void
    },
    submit: {
      variant?: VariantType,
      icon?: BUTTON_ICON_LIST_TYPE | undefined,
      text: string,
      onClick: () => void
    }
  }
}

export const Dialog_v2 = ({
  isOpen,
  props
}: IDialogProps) => {
  const {
    title,
    subtitle,
    cancel,
    submit
  } = props;

  return (
    <Dialog
      onClose={cancel.onClick}
      open={isOpen}
      classes={{
        paper: styles.Paper
      }}
    >
      <DialogTitle>
        <div className={styles.Title}>
          {title}
        </div>
        <button
          className={styles.CloseButton}
          onClick={() => cancel.onClick()}
        >
          <CloseIcon />
        </button>
      </DialogTitle>
      <DialogContent>
        {subtitle && (
          <div className={styles.Subtitle}>
            {subtitle}
          </div>
        )}
        <div className={styles.Actions}>
          <Button
            variant={cancel?.variant || 'blackTextBlackBorderOutlined'}
            icon={cancel?.icon}
            text={cancel.text}
            onClick={cancel.onClick}
          />
          <Button
            variant={submit?.variant || 'danger'}
            icon={submit?.icon}
            text={submit.text}
            onClick={submit.onClick}
          />
        </div>
      </DialogContent>
    </Dialog>
  )
}