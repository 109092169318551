import React, {useMemo} from 'react'
import { Form } from 'react-final-form'
import { defineMessages, useIntl } from 'react-intl'
import { useAddContact } from '../_hooks/use-add-contact/useAddContact'
import { useModal } from '../../../app/hooks/useModal'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg'
import { useBackendErrorCodes } from '../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import { formatPhoneNumber } from '../../../sdk/formatters/format-phone-number'
import { useDevices } from '../../../sdk/hooks/use-devices/useDevices'
import { SelectSearchField } from '../../../shared/lib/form/form-field-adapters/v2/select-search-field/SelectSearchField'
import { clearPhoneMutator } from '../../../shared/lib/form/form-helpers/mutators'
import { useFormRules } from '../../../shared/lib/form/form-rules'
import { getErrorMessage } from '../../../shared/lib/utils/get-error-message/getErrorMessage'
import { Drawer } from '../../../shared/ui-kit-2/data-display/drawer/Drawer'
import { Button } from '../../../shared/ui-kit-2/inputs/button/Button'
import { Input } from '../../../shared/ui-kit-2/inputs/input/Input'
import { SelectSearchOption } from '../../../shared/ui-kit-2/inputs/select-search/SelectSearch'
import { TextButton } from '../../../shared/ui-kit-2/inputs/text-button/TextButton'
import { LoadingContent } from '../../loading-content/LoadingContent'
import { SNACKBAR_TYPES } from '../../snackbar/SnackbarProvider'
import styles from './styles.module.scss'
import {
  PhoneInputFieldNumber
} from "../../../shared/lib/form/form-field-templates/v2/phone-input-field-new/PhoneInputFieldNew";
import {getClearedPhoneNumber} from "../../../shared/lib/utils/get-cleared-phone-number/getClearedPhoneNumber";
import {sipUserGetDatagate} from "../../../sdk/datagates/api/sip-user";
import {useDevice} from "../../../sdk/hooks/use-device/useDevice";
import {MODAL_TYPES} from "../ModalsProvider";
import {HelperFrame} from "../../../shared/ui-kit-2/data-display/helper-frame/HelperFrame";

type CreateCallFormType = {
  dongleId: number
  phone?: string
}

const CreateCallModalMessages = defineMessages({
  title: {
    id: 'CreateCallModalMessages.title',
    defaultMessage: 'Create call',
  },
  cancel: {
    id: 'CreateCallModalMessages.cancel',
    defaultMessage: 'Cancel',
  },
  call: {
    id: 'CreateCallModalMessages.call',
    defaultMessage: 'Call',
  },
  deviceLabel: {
    id: 'CreateCallModalMessages.deviceLabel',
    defaultMessage: 'Modem',
  },
  devicePlaceholder: {
    id: 'CreateCallModalMessages.devicePlaceholder',
    defaultMessage: 'Select modem',
  },
  phoneLabel: {
    id: 'CreateCallModalMessages.phoneLabel',
    defaultMessage: 'Phone number',
  },
  contacts: {
    id: 'CreateCallModalMessages.contacts',
    defaultMessage: 'Contacts',
  },
  contactLabel: {
    id: 'CreateCallModalMessages.contactLabel',
    defaultMessage: 'Contact',
  },
  positiveAdded: {
    id: 'CreateCallModalMessages.positiveAdded',
    defaultMessage: 'Call is successfully created',
  },
  negativeAdded: {
    id: 'CreateCallModalMessages.negativeAdded',
    defaultMessage: 'An error occurred while call',
  },
  globalHelpText: {
    id: 'CreateCallModalMessages.globalHelpText',
    defaultMessage: 'If your modem is not in the list of modems, you need to switch its type from robotic calls to voice calls in the settings'
  },
  modemHelpText: {
    id: 'CreateCallModalMessages.modemHelpText',
    defaultMessage: 'For Voice Calls to work correctly, the modem must have Voice Over LTE feature enabled'
  },
})

export const CreateCallModal = () => {
  const intl = useIntl()
  const {
    handleOpenModal,
    handleHideModal,
    props: {
      makeCall,
      answerIncomingCall,
      endCall,
      hasIncomingCall,
      callHasAcceptedByOtherSide,
    }
  } = useModal()
  const { handleOpenSnackbar } = useSnackbar()
  const { resolveBackendError } = useBackendErrorCodes()
  const { ruleRequired } = useFormRules()

  const {
    loading: deviceLoading,
    handleFetch: handleFetchDevice
  } = useDevice({ noInitialFetch: true })

  const { devices, loading: devicesLoading } = useDevices({ page: 0, limit: 0, takeAll: true, brandId: 2 })

  const dongleOptions: SelectSearchOption[] = useMemo(
    () =>
      devices.map((d) => {
        return {
          key: d.dongleId,
          label: d.name,
          inputLabel: d.name,
          value: d.dongleId.toString(),
        }
      }),
    [devices],
  )

  const {
    component: contactSelectComponent,
    setIsOpen: setIsContactSelectOpen,
    savedContact,
    removeContact,
  } = useAddContact()

  const handleCall = async (values: CreateCallFormType) => {
    try {
      const toName = savedContact?.name;
      const toPhoneNumber = savedContact ? savedContact.phone : values?.phone;
      const formattedToPhoneNumber = toPhoneNumber?.replaceAll(' ', '');
      const { dongleId } = values;

      if (toPhoneNumber && dongleId) {
        const fromDevice = await handleFetchDevice(dongleId, 2);

        if (fromDevice && formattedToPhoneNumber) {
          const formattedImeiManufacture = fromDevice.imeiManufacture.replace(/\D/g, '');

          makeCall({
            targetUriSip: formattedImeiManufacture,
            targetUriID: formattedToPhoneNumber,
          })

          handleOpenModal({
            type: MODAL_TYPES.CALL,
            props: {
              from: {
                name: fromDevice.name,
                phoneNumber: fromDevice.phoneNumber,
                imeiManufacture: fromDevice.imeiManufacture,
              },
              to: {
                name: toName,
                phoneNumber: formattedToPhoneNumber,
              },
              sip: {
                answerIncomingCall,
                endCall,
                hasIncomingCall,
                callHasAcceptedByOtherSide,
              }
            }
          })
        }
      }
    } catch(e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(
          getErrorMessage(e),
          intl.formatMessage(CreateCallModalMessages.negativeAdded),
        ),
      })
    }
  }

  const openContactsButton = (
    <TextButton
      text={intl.formatMessage(CreateCallModalMessages.contacts)}
      icon={'contact'}
      onClick={() => setIsContactSelectOpen(true)}
    />
  )

  const content = (
    <Form
      onSubmit={handleCall}
      initialValues={{
        dongleId: null,
      }}
      mutators={{
        clearPhoneMutator,
      }}
      render={({ handleSubmit, submitting }) => {
        return (
          <form onSubmit={handleSubmit} className={styles.Container}>
            <HelperFrame text={intl.formatMessage(CreateCallModalMessages.modemHelpText)} />
            <SelectSearchField
              name="dongleId"
              validate={ruleRequired()}
              options={dongleOptions}
              label={intl.formatMessage(CreateCallModalMessages.deviceLabel)}
              placeholder={intl.formatMessage(
                CreateCallModalMessages.devicePlaceholder,
              )}
              markAsRequired={true}
              helperText={intl.formatMessage(CreateCallModalMessages.modemHelpText)}
              dropdownElementsInView={12}
            />

            {savedContact ? (
              <Input
                value={`${savedContact.name}: ${formatPhoneNumber(savedContact.phone)}`}
                label={intl.formatMessage(CreateCallModalMessages.contactLabel)}
                readOnly={true}
                postfix={
                  <TrashIcon
                    onClick={removeContact}
                    className={styles.DeleteContact}
                  />
                }
                markAsRequired={true}
              />
            ) : (
              <PhoneInputFieldNumber
                validate={ruleRequired()}
                name="phone"
                label={intl.formatMessage(CreateCallModalMessages.phoneLabel)}
                markAsRequired={true}
                postfix={openContactsButton}
              />
            )}

            <div className={styles.Actions}>
              <Button
                text={intl.formatMessage(CreateCallModalMessages.cancel)}
                variant='blackTextBlackBorderOutlined'
                onClick={handleHideModal}
                disabled={submitting}
              />
              <Button
                type={'submit'}
                text={intl.formatMessage(CreateCallModalMessages.call)}
                disabled={submitting}
                loading={submitting || deviceLoading}
              />
            </div>
          </form>
        )
      }}
    />
  )

  return (
    <Drawer
      title={intl.formatMessage(CreateCallModalMessages.title)}
      isOpen={true}
      close={handleHideModal}
      additionalClassNames={[styles.DrawerAdditional]}
    >
      <LoadingContent loading={devicesLoading} content={content} />

      {contactSelectComponent}
    </Drawer>
  )
}
