import React, { useMemo } from 'react'
import clsx from 'clsx'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router'
import { ReactComponent as ContactsIcon } from '../../assets/icons/header-dropdown-contacts.svg'
import { ReactComponent as CallsIcon } from '../../assets/icons/sidebar-calls.svg'
import { ReactComponent as DevicesIcon } from '../../assets/icons/sidebar-devices.svg'
import { ReactComponent as SmsIcon } from '../../assets/icons/sidebar-sms.svg'
import { ReactComponent as WifiIcon } from '../../assets/icons/sidebar-wifi.svg'
import { ReactComponent as VPNIcon } from '../../assets/icons/sidebar-vpn.svg'
import { AuthRoutePath } from '../../config/routes/constants/authRoutePath'
import { RoutePath } from '../../config/routes/constants/routePath'
import { SmsRoutePath } from '../../config/routes/constants/smsRoutePath'
import { AuthRoute } from '../../config/routes/enums/authRoute'
import { Route } from '../../config/routes/enums/route'
import { SmsRoute } from '../../config/routes/enums/smsRoute'
import { useAppSelector } from '../../store'
import { LC } from '../../tests/e2e/locators'
import {
  SidebarAndTabBarPagesMessages,
  SidebarLink,
} from '../sidebar/components/sidebar-pages/SidebarPages'
import styles from './styles.module.scss'
import {CallsRoutePath} from "../../config/routes/constants/callsRoutePath";
import {CallsRoute} from "../../config/routes/enums/callsRoute";

const SidebarNotShownRoutePathGroup = [
  Route.Home,
  Route.PurchaseProcess,
  Route.TermsOfUse,
  Route.PrivacyPolicy,
  Route.LegalNotice,
].map((route) => RoutePath[route])

const SidebarNotShownAuthRoutePathGroup = [
  AuthRoute.SignIn,
  AuthRoute.SignUp,
  AuthRoute.RecoverPassword,
  AuthRoute.ThirdParty,
  AuthRoute.ThirdParty,
].map((route) => AuthRoutePath[route])

const SidebarNotShownPathGroup = [
  ...SidebarNotShownRoutePathGroup,
  ...SidebarNotShownAuthRoutePathGroup,
]

export const TabBar = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  const { pathname } = useLocation()
  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated)

  const isSidebarHidden = useMemo(
    () => SidebarNotShownPathGroup.some((path) => path === pathname),
    [pathname],
  )

  const pages: SidebarLink[] = [
    {
      key: SmsRoutePath[SmsRoute.SmsList],
      icon: <SmsIcon />,
      path: SmsRoutePath[SmsRoute.SmsList],
      label: intl.formatMessage(SidebarAndTabBarPagesMessages.sms),
      testId: LC.HEADER.SMS_BTN,
    },
    {
      key: CallsRoutePath[CallsRoute.CallsList],
      icon: <CallsIcon />,
      path: CallsRoutePath[CallsRoute.CallsList],
      label: intl.formatMessage(SidebarAndTabBarPagesMessages.calls),
      testId: LC.HEADER.CALLS_BTN,
    },
    {
      key: RoutePath[Route.WifiList],
      icon: <WifiIcon />,
      path: RoutePath[Route.WifiList],
      label: intl.formatMessage(SidebarAndTabBarPagesMessages.wifi),
      testId: LC.HEADER.WIFI_BTN,
    },
    /*
    {
      key: RoutePath[Route.VPN],
      icon: <VPNIcon />,
      path: RoutePath[Route.VPN],
      label: intl.formatMessage(SidebarAndTabBarPagesMessages.vpn),
      testId: LC.HEADER.DEVICES_BTN,
    },
    */
    {
      key: RoutePath[Route.DeviceList],
      icon: <DevicesIcon />,
      path: RoutePath[Route.DeviceList],
      label: intl.formatMessage(SidebarAndTabBarPagesMessages.devices),
      testId: LC.HEADER.DEVICES_BTN,
    },
    {
      key: RoutePath[Route.Contacts],
      icon: <ContactsIcon />,
      path: RoutePath[Route.Contacts],
      label: intl.formatMessage(SidebarAndTabBarPagesMessages.contacts),
      testId: LC.HEADER.CONTACTS_BTN,
    },
  ]

  if (isSidebarHidden || !isAuthenticated) return null

  return (
    <div className={styles.Container}>
      {pages.map((page) => (
        <div
          key={page.key}
          className={clsx(
            styles.PageButton,
            pathname === page.path && styles.PageButtonActive,
          )}
          onClick={() => navigate(page.path)}
        >
          <div>{page.icon}</div>
          <div>{page.label}</div>
        </div>
      ))}
    </div>
  )
}
